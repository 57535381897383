// RegistrationForm.js
import React, { useState, useEffect, useLocation } from "react";
import { Formik, useFormik } from "formik";
import FormInput from "../components/formInputs";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import app_api from "../config";
import {
  registerBusinessContact,
  registerSameAsBillBusinessContact,
  registerIndividualContact,
} from "../schema";
import { useAuth } from "../auth/authContext";
import Select from "react-select"

const RegistrationForm = ({ selectedOption }) => {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [ischeck, setIscheck] = useState(false);

  const [shipaddress1, setShipAddress1] = useState();
  const [shipaddress2, setShipAddress2] = useState();
  const [zip2, setZip2] = useState();
  const [city2, setCity2] = useState();
  const [shipstate2, setShipState2] = useState();
  const [disbaleShip, setDisableShip] = useState(false);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [countries2, setCountries2] = useState();
  const [states2, setStates2] = useState();

  const { setAuth } = useAuth();
 
  
  const colourStyles = {
    control: (styles, state) => ({ ...styles, backgroundColor: state.isDisabled ? '#78716C' : '#010519', borderColor: '#E4A695',height: '45px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#010519',
        color: isSelected ? 'white' : '#E4A695',
        cursor: 'default',
        zIndex: 1000,
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white', // Set the text color to white for the selected value
      fontSize: '15px'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#334154', // Set the color of the placeholder text
      fontSize: '15px'
    }),
  };

  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("solitaireKey"))))
      setAuth(true);
  });

  useEffect(() => {
    getAllCountries()
  },[])

  const getAllCountries = () => {
    app_api.get('auth/country')
    .then((res) => {
      setCountries(res.data.data)
      setCountries2(res.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState2 = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates2(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleCheckboxdub = (values) => {
    setIscheck(!ischeck);
    if (ischeck) {
      setShipAddress1(values.billaddress1);
      setShipAddress2(values.billaddress2);
      setZip2(values.zipCode1);
      setCity2(values.city1);
      setShipState2(values.state1);
      setDisableShip(true);
    } else {
      setShipAddress1("");
      setShipAddress2("");
      setZip2("");
      setCity2("");
      setShipState2("");
      setDisableShip(false);
    }
  };

  useEffect(() => {
    setUserType(selectedOption);
  }, [userType]);

  // console.log(selectedOption);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    number: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    billaddress1:"",
    city1:"",
    zipCode1:"",
    shipaddress1: "",
    shipaddress2: "",
    city2: "",
    zipCode2: "",
    state2: "",
  });

  const renderModal = () => {
    return (
      <Formik
        initialValues={formData}
        validationSchema={
          userType == "individual"
            ? registerIndividualContact
            : userType == "business" && ischeck == false
              ? registerBusinessContact
              : registerSameAsBillBusinessContact
        }
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (ischeck == true && userType == 'business') {
            values = { ...values, sameAsBillTo: true };
            delete values.shipaddress1;
            delete values.shipaddress2;
            delete values.city2;
            delete values.zipCode2;
            delete values.state2;
          }
          if (ischeck == false && userType == 'business') {
            values = { ...values, sameAsBillTo: false };
          }
          delete values.confirmPassword;
          app_api
            .post("user/register", {
              ...values,
              role: userType == "individual" ? "INDIVIDUAL" : "BUSINESS",
            })
            .then((res) => {
              toast.success("Registered Successfully");
              setSubmitting(false);
              resetForm();
              localStorage.setItem(
                "solitaireKey",
                JSON.stringify(res.data.data)
              );
              setTimeout(function () {
                if (userType == 'business') {
                  navigate('/kyc')
                }
                else {
                  navigate("/");
                }
              }, 2000);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setSubmitting(false);
            });
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            {userType == "individual" && (
              <>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 px-4 md:px-20 pb-10">
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        First Name
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="first_name"
                      label="first_name"
                      value={values.first_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter First Name..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.first_name && (
                      <p className="text-red-700 error_msg">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Last Name
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="last_name"
                      label="last_name"
                      value={values.last_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Last Name..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.last_name && (
                      <p className="text-red-700 error_msg">
                        {errors.last_name}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        E-mail ID
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="email"
                      label="email"
                      autoComplete="off"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Email Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:bg-inherit focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Phone Number
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="number"
                      label="number"
                      type="tel"
                      value={values.number}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Phone Number..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.number && (
                      <p className="text-red-700 error_msg">{errors.number}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Address
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="billaddress1"
                      label="billaddress1"
                      value={values.billaddress1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.billaddress1 && (
                      <p className="text-red-700 error_msg">{errors.billaddress1}</p>
                    )}
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country1"
                      id="country1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country1: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country1 && (
                        <p className="text-red-700 error_msg">
                          {errors.country1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state1"
                      id="state1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={states?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state1: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        City
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="city1"
                      label="city1"
                      value={values.city1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter City..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.city1 && (
                      <p className="text-red-700 error_msg">{errors.city1}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Zip Code
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="zipCode1"
                      label="zipCode1"
                      value={values.zipCode1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Zip Code..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.zipCode1 && (
                      <p className="text-red-700 error_msg">{errors.zipCode1}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Password
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="password"
                      label="password"
                      type="password"
                      autoComplete="off"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter New Password..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.password && (
                      <p className="text-red-700 error_msg">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Confirm Password
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="confirmPassword"
                      label="confirmPassword"
                      type="password"
                      value={values.confirmPassword}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Confirm Password..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.confirmPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}

            {userType == "business" && (
              <>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 px-4 md:px-20 pb-10">
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        First Name
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="first_name"
                      label="first_name"
                      value={values.first_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter First Name..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.first_name && (
                      <p className="text-red-700 error_msg">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Last Name
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="last_name"
                      label="last_name"
                      value={values.last_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Last Name..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.last_name && (
                      <p className="text-red-700 error_msg">
                        {errors.last_name}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        E-mail ID
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="email"
                      label="email"
                      autoComplete="off"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Email Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:bg-inherit focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Phone Number
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="number"
                      label="number"
                      type="tel"
                      value={values.number}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Phone Number..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.number && (
                      <p className="text-red-700 error_msg">{errors.number}</p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Password
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="password"
                      label="password"
                      type="password"
                      autoComplete="off"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter New Password..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.password && (
                      <p className="text-red-700 error_msg">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Confirm Password
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="confirmPassword"
                      label="confirmPassword"
                      type="password"
                      value={values.confirmPassword}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Confirm Password..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.confirmPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 px-4 md:px-20 pb-10">
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Company Name
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="companyName"
                      label="companyName"
                      value={values.companyName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Business Name..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.companyName && (
                      <p className="text-red-700 error_msg">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                </div>

                <div className="md:mb-0 mb-4 mx-20 block text-2xl font-medium text-white">
                  Bill To
                </div>
                <div className="mt-4"></div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 px-4 md:px-20 pb-10">
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Address Line 1
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="billaddress1"
                      label="address"
                      value={values.billaddress1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.billaddress1 && (
                      <p className="text-red-700 error_msg">
                        {errors.billaddress1}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Address Line 2
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="billaddress2"
                      label="address"
                      value={values.billaddress2}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.billaddress2 && (
                      <p className="text-red-700 error_msg">
                        {errors.billaddress2}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Zip Code
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="zipCode1"
                      label="zipCode"
                      value={values.zipCode1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Zip Code..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.zipCode1 && (
                      <p className="text-red-700 error_msg">
                        {errors.zipCode1}
                      </p>
                    )}
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country1"
                      id="country1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country1: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country1 && (
                        <p className="text-red-700 error_msg">
                          {errors.country1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state1"
                      id="state1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={states?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state1: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        City
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="city1"
                      label="city"
                      value={values.city1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter City..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.city1 && (
                      <p className="text-red-700 error_msg">{errors.city1}</p>
                    )}
                  </div>
                </div>

                <div className="md:mb-0 mb-4 mx-20 block text-2xl font-medium text-white">
                  Ship To <small>(PO Boxes not allowed)</small>
                </div>
                <div className="flex flex-col md:mx-20 md:flex-row gap-4">
                  <div className="my-2 col-md-12">
                    <label
                      className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                      for="sameAsBillTo"
                    >
                      <input
                        type="checkbox"
                        // className="absolute left-0 z-[-1] w-6 h-6  top-0"
                        className="absolute left-0 z-[-1] w-6 h-6 top-0"
                        id="enableAutoFill"
                        checked={ischeck}
                        onChange={() => {
                          handleCheckboxdub(values);
                        }}
                        disabled={
                          values.billaddress1 != undefined &&
                            values.billaddress2 != undefined &&
                            values.city1 != '' &&
                            values.state1 != undefined &&
                            values.zipCode1 != undefined &&
                            values.country1 != undefined
                            ? false
                            : true
                        }
                      />
                      <span className="pl-3 font-normal text-[#E4A795] cursor-pointer select-none">
                        Same as Bill To
                      </span>
                    </label>
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 px-4 md:px-20 pb-10">
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Address Line 1
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="shipaddress1"
                      label="address"
                      value={
                        ischeck == true
                          ? values.billaddress1
                          : values.shipaddress1
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={ischeck}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:bg-stone-500"
                    />
                    {touched.shipaddress1 && (
                      <p className="text-red-700 error_msg">
                        {errors.shipaddress1}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Address Line 2
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="shipaddress2"
                      label="address"
                      value={
                        ischeck == true
                          ? values.billaddress2
                          : values.shipaddress2
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={ischeck}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:bg-stone-500"
                    />
                    {touched.shipaddress2 && (
                      <p className="text-red-700 error_msg">
                        {errors.shipaddress2}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        Zip Code
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="zipCode2"
                      label="zipCode"
                      value={
                        ischeck == true ? values.zipCode1 : values.zipCode2
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={ischeck}
                      placeholder="Enter Zip Code..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:bg-stone-500"
                    />
                    {touched.zipCode2 && (
                      <p className="text-red-700 error_msg">
                        {errors.zipCode2}
                      </p>
                    )}
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country2"
                      id="country2"
                      isSearchable
                      styles={colourStyles}
                      isDisabled={ischeck}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        ischeck ?
                        {
                          value: countries?.find(
                            (e) => e.name == values.country1
                          )?.isoCode,
                          label: countries?.find(
                            (e) => e.name == values.country1
                          )?.name,
                        }
                        :
                        values.country2
                          ? countries2?.map((l) => ({
                            value: countries2?.find(
                              (e) => e.name == values.country2
                            )?.isoCode,
                            label: countries2?.find(
                              (e) => e.name == values.country2
                            )?.name,
                          }))
                          : null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country2: option.label,
                        }));
                        getState2(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country2 && (
                        <p className="text-red-700 error_msg">
                          {errors.country2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="flex">
                        <label className="block text-xl font-medium text-[#E4A795]">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state2"
                      id="state2"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={states2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        ischeck ?
                        {
                          value: states?.find(
                            (e) => e.name == values.state1
                          )?.isoCode,
                          label: states?.find(
                            (e) => e.name == values.state1
                          )?.name,
                        }
                        :
                        values.state2
                          ? states2?.map((l) => ({
                            value: states2?.find(
                              (e) => e.name == values.state2
                            )?.isoCode,
                            label: states2?.find(
                              (e) => e.name == values.state2
                            )?.name,
                          }))
                          : null
                      }
                      isDisabled={ischeck}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state2: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state2 && (
                        <p className="text-red-700 error_msg">
                          {errors.state2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <label className="block text-xl font-medium text-[#E4A795]">
                        City
                      </label>
                      <span className="text-white ml-1">*</span>
                    </div>
                    <input
                      name="city2"
                      label="city"
                      value={ischeck == true ? values.city1 : values.city2}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={ischeck}
                      placeholder="Enter City..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:bg-stone-500"
                    />
                    {touched.city2 && (
                      <p className="text-red-700 error_msg">{errors.city2}</p>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="flex justify-center mt-[2%]">
              <button
                className="py-4 px-6 rounded-lg font-semibold text-xl text-black bg-[#E4A795]"
                type="submit"
              >
                Register
              </button>
            </div>
            <div className="flex justify-center mt-4 pb-4 pb-[3%]">
              <Link
                className="font-semibold text-xl text-[#E4A795]"
                to="/signin"
              >
                back to login
              </Link>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className="text-white px-4 pt-4">
      <div className="container mx-auto my-auto">
        <div className="flex gap-5 mb-5">
          <Link className="font-semibold text-xl text-[#E4A795]" to="/signin">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="md:w-16 w-12 md:h-16 h-12 font-normal"
              style={{ color: "#E4A795" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Link>
          <h1 className="font-normal md:text-6xl text-5xl">Register</h1>
        </div>
        {renderModal()}
      </div>
      <ToastContainer />
    </div>
  );
};

export default RegistrationForm;
