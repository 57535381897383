
import * as Yup from 'yup'


export const validationContact = Yup.object({
    name: Yup.string().required('Required'),
    phone: Yup.string().required('Required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  export const registerIndividualContact = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    number: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    billaddress1: Yup.string().required('Address is required'),
    city1: Yup.string().required('City is required'),
    state1: Yup.string().required('State is required'),
    country1: Yup.string().required('Country is required'),
    zipCode1: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    password: Yup.string()
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Please enter the password again to confirm')
      .oneOf([Yup.ref('password'), null], 'Password must match'),
  });

  export const registerBusinessContact = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    number: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    companyName: Yup.string().required('Company Name is required'),
    billaddress1: Yup.string().required('Address Line 1 is required'),
    billaddress2: Yup.string().required('Address Line 2 is required'),
    city1: Yup.string().required('City is required'),
    zipCode1: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    state1: Yup.string().required('State is required'),
    country1: Yup.string().required('Country is required'),
    country2: Yup.string().required('Country is required'),
    shipaddress1: Yup.string().required('Address Line 1 is required'),
    shipaddress2: Yup.string().required('Address Line 2 is required'),
    city2: Yup.string().required('City is required'),
    zipCode2: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    state2: Yup.string().required('State is required'),
    password: Yup.string()
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Please enter the password again to confirm')
      .oneOf([Yup.ref('password'), null], 'Password must match'),
  });

  export const registerSameAsBillBusinessContact = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    number: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    companyName: Yup.string().required('Company Name is required'),
    billaddress1: Yup.string().required('Address Line 1 is required'),
    billaddress2: Yup.string().required('Address Line 2 is required'),
    city1: Yup.string().required('City is required'),
    country1: Yup.string().required('Country is required'),
    zipCode1: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    state1: Yup.string().required('State is required'),
    password: Yup.string()
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Please enter the password again to confirm')
      .oneOf([Yup.ref('password'), null], 'Password must match'),
  });

  export const loginFormSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  export const forgotPasswordSchema = Yup.object({
    newPassword: Yup.string()
      .required('Password is required')
      .test('greater or equal to 4','Password should be atleast 4 character long', value => value.split(' ').join('').length >= 4),
    confirmPassword: Yup.string()
      .required('Please enter the password again to confirm')
      .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
  })

  
  export const loginIndividualSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  export const KycSchema = Yup.object({
    companyName: Yup.string().required('Comapny Name is required'),
    companyEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    businessTel: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    mainLastName: Yup.string().required('Last Name is required'),
    mainFirstName: Yup.string().required('First Name is required'),
    mainEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    mainPhoneNo: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    // addressLine2: Yup.string().required('Address Line 2 is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    state: Yup.string().required('State is required'),
    resaleCertificate: Yup.string().required('This field is required'),
    applicantName: Yup.string().required('Applicant name is required'),
    refCompanyName1: Yup.string(),
    refPhoneNumber1: Yup.string(),
    pointOfContact1: Yup.string(),
    refEmail1: Yup.string().email('Invalid Email Address'),
    refCompanyName2: Yup.string().test(
      'is-unique',
      'Please use another company name',
      function (value) {
        const { refCompanyName1 } = this.parent;
        return value !== refCompanyName1;
      }
    ),
    refPhoneNumber2: Yup.string().test(
      'is-unique',
      'Please use another phone number',
      function (value) {
        const { refPhoneNumber1 } = this.parent;
        return value !== refPhoneNumber1;
      }
    ),
    pointOfContact2: Yup.string().test(
      'is-unique',
      'Please use another point of contact',
      function (value) {
        const { pointOfContact1 } = this.parent;
        return value !== pointOfContact1;
      }
    ),
    refEmail2: Yup.string().email('Invalid Email Address').test(
      'is-unique',
      'Please use another email address',
      function (value) {
        const { refEmail1 } = this.parent;
        return value !== refEmail1;
      }
    ),
    refCompanyName3: Yup.string().test(
      'is-unique',
      'Please use another company name',
      function (value) {
        const { refCompanyName1, refCompanyName2 } = this.parent;
        return value !== refCompanyName1 || value !== refCompanyName2;
      }
    ),
    refPhoneNumber3: Yup.string().test(
      'is-unique',
      'Please use another phone number',
      function (value) {
        const { refPhoneNumber1, refPhoneNumber2 } = this.parent;
        return value !== refPhoneNumber1 || value !== refPhoneNumber2;
      }
    ),
    pointOfContact3: Yup.string().test(
      'is-unique',
      'Please use another point of contact',
      function (value) {
        const { pointOfContact1, pointOfContact2 } = this.parent;
        return value !== pointOfContact1 || value !== pointOfContact2;
      }
    ),
    refEmail3: Yup.string().email('Invalid Email Address').test(
      'is-unique',
      'Please use another email address',
      function (value) {
        const { refEmail1, refEmail2 } = this.parent;
        return value !== refEmail1 || value !== refEmail2;
      }
    ),
  });

  export const KycSchemaCreditCard = Yup.object({
    companyName: Yup.string().required('Comapny Name is required'),
    companyEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    businessTel: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    mainLastName: Yup.string().required('Last Name is required'),
    mainFirstName: Yup.string().required('First Name is required'),
    mainEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    mainPhoneNo: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    // addressLine2: Yup.string().required('Address Line 2 is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
    state: Yup.string().required('State is required'),
    resaleCertificate: Yup.string().required('This field is required'),
    applicantName: Yup.string().required('Applicant name is required'),
  });