import React from 'react';

const MyModal = ({ isOpen, onClose, children }) => {
  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (outside the modal content)
    // if (e.target.classList.contains('overlay')) {
    //   onClose();
    // }
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      {isOpen && (
        // <div className="fixed inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
        //   <div className="relative z-50 bg-white max-w-md mx-auto p-6 rounded shadow-lg">
        //     <span
        //       className="absolute top-0 right-0 p-4 cursor-pointer"
        //       onClick={onClose}
        //     >
        //       &times;
        //     </span>
        //     {children}
        //   </div>
        //   <div className="fixed inset-0 bg-black opacity-50"></div>
        // </div>
        <div
          className="fixed inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none"
          onClick={handleOverlayClick}
        >
          <div className="relative z-50 bg-white max-w-md mx-auto p-6 rounded shadow-lg">
            <span
              className="absolute top-0 right-0 p-4 cursor-pointer"
              onClick={onClose}
            >
              &times;
            </span>
            {children}
          </div>
          <div className="fixed inset-0 bg-black opacity-50 overlay"></div>
        </div>
      )}
    </>
  );
};

export default MyModal;
