import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

export const Logout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    LogoutUser()
  }, [])

  const LogoutUser = () => {
    let user = JSON.parse(localStorage.getItem('solitaireKey'))
    if (user?.email) {
          localStorage.removeItem('solitaireKey');
          navigate("/")
          window.location.reload();
    }
    else{
        navigate("/")
    }
  }
  
}
