import { IoPlayCircleOutline } from "react-icons/io5";

export const catalogue_column = ({ onCheckBox }) => [
  {
    id: "check",
    accessor: "checked",
    Cell: (cell) => <input type="checkbox" className="cursor-pointer" checked={cell.row.original.checked} onChange={() =>{
      onCheckBox(cell.row.original.id, cell.row.index)
    }} />
  },
  {
    Header: "Packet No",
    accessor: "packetNo",
  },
  {
    Header: "Sr No.",
    accessor: "id",
  },
  {
    Header: "Lab",
    accessor: "lab",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.reportNo ? (
            <span className="inline-flex px-2 text-sm leading-5 text-black bg-[#E4A795] rounded-full">
              <a
                href={`https://www.gia.edu/report-check?reportno=${cell.row.original.reportNo}`}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
                target="_blank"
              >
                {cell.row.original.lab}<span className="sr-only">, </span>
              </a>
            </span>):(
              <span className="inline-flex px-2 text-sm leading-5 text-black bg-[#E4A795] rounded-full">
                {cell.row.original.lab}
              </span>
            )}
        </>
      );
    },
  },
  {
    Header: "Report No",
    accessor: "reportNo",
  },
  {
    Header: "DNA",
    Cell: (cell) => {
      return (
        <span className="inline-flex px-2 text-sm leading-5 text-black bg-[#e4a795] rounded-full">
          <a
            href={`https://views.diadna.com/dna/${cell.row.original.packetNo}`}
            className="text-black"
            size="default"
            variant="outlined"
            target="_blank"
          >
            DNA<span className="sr-only">, </span>
          </a>
        </span>
      );
    },
  },
  {
    Header: "Video",
    Cell: (cell) => {
      return (
        <a
          href={`https://views.diadna.com/diamondfullview/${cell.row.original.packetNo}`}
          className="text-[#e4a795] flex justify-center"
          size="default"
          variant="outlined"
          target="_blank"
        >
          <IoPlayCircleOutline className="w-6 h-6" />
        </a>
      );
    },
  },
  {
    Header: "Naked Eye",
    Cell: (cell) => {
      return (
        <span className="inline-flex px-2 text-sm leading-5 text-black bg-[#e4a795] rounded-full">
          <a
            href={`https://views.diadna.com/diamondVideoview/${cell.row.original.packetNo}`}
            className="text-black"
            size="default"
            variant="outlined"
            target="_blank"
          >
            Naked Eye<span className="sr-only">, </span>
          </a>
        </span>
      );
    },
  },
  {
    Header: "Shape",
    accessor: "shape",
  },
  {
    Header: "Carat",
    accessor: d => '$ ' + d.carat.toFixed(2) ,
  },
  {
    Header: "Color",
    accessor: "color",
  },
  {
    Header: "Clarity",
    accessor: "clarity",
  },
  {
    Header: "Shade",
    accessor: "shade",
  },
  {
    Header: "Rap($)",
    accessor: "rap",
  },
  {
    Header: "Discount",
    accessor: d =>  d.discount.toFixed(2) + ' %',
  },
  {
    Header: "Price Per Carat",
    accessor: d => '$ ' + d.price.toFixed(2) ,
  },
  {
    Header: "Amount",
    accessor: d => '$ ' + (d.price * d.carat).toFixed(2),
  },
  {
    Header: "Cut",
    accessor: "cut",
  },
  {
    Header: "Pol",
    accessor: "polish",
  },
  {
    Header: "Sym",
    accessor: "symmetry",
  },
  {
    Header: "Fls",
    accessor: "fluorescence",
  },
  {
    Header: "Brl",
    accessor: "brilliancy",
  },
  {
    Header: "Length",
    accessor: "length",
  },
  {
    Header: "Depth",
    accessor: "depth",
  },
  {
    Header: "Table%",
    accessor: "tableper",
  },
  {
    Header: "Depth%",
    accessor: "depthper",
  },
  {
    Header: "Ratio",
    accessor: "ratio",
  },
  {
    Header: "CN",
    accessor: "centerNatts",
  },
  {
    Header: "SN",
    accessor: "sideNatts",
  },
  {
    Header: "CW",
    accessor: "centerWhite",
  },
  {
    Header: "SW",
    accessor: "sideWhite",
  },
  {
    Header: "TO",
    accessor: "tableOpen",
  },
  {
    Header: "CO",
    accessor: "crownOpen",
  },
  {
    Header: "PO",
    accessor: "pavillionOpen",
  },
  {
    Header: "Eye Clean",
    accessor: (d) => d.EyeClean ? 'Yes' : 'No',
  },
  {
    Header: "C/A",
    accessor: "crownAngle",
  },
  {
    Header: "C/H",
    accessor: "crownHeight",
  },
  {
    Header: "P/A",
    accessor: "pavillionAngle",
  },
  {
    Header: "P/H",
    accessor: "pavillionHeight",
  },
  {
    Header: "Girdle",
    accessor: "girdle",
  },
  {
    Header: "Culet",
    accessor: "culet",
  },
  {
    Header: "Laser Ins",
    accessor: (d) => d.laserIns ? 'Yes' : 'No',
  },
  {
    Header: "H&A",
    accessor: "heartArrow",
  },
  {
    Header: "Report Comments",
    accessor: "reportComment",
  },
  {
    Header: "Key To Symbols",
    // accessor: "color",
  },
  {
    Header: "Lower",
    accessor: "lower",
  },
  {
    Header: "Star",
    accessor: "star",
  },
  {
    Header: "Gridle%",
    accessor: "girdlePer",
  },
  {
    Header: "Seal",
    accessor: "seal",
  },
  {
    Header: "Pair Packet No",
    accessor: "pairPocketNo",
  },
  {
    Header: "DOR",
    accessor: "dor",
  },
  {
    Header: "CM No",
    accessor: "cmNo",
  },
  {
    Header: "FPC",
    accessor: "fpc",
  },
  {
    Header: "X-ray",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.xray && (
            <span className="inline-flex px-2 text-sm leading-5 text-black bg-[#e4a795] rounded-full">
              <a
                href={`https://client.belgiumny.com/xrays/${cell.row.original.packetNo}`}
                className="text-black"
                size="default"
                variant="outlined"
                target="_blank"
              >
                X-Ray<span className="sr-only">, </span>
              </a>
            </span>
          )}
        </>
      );
    },
  },
  {
    Header: "Type IICert",
    accessor: "typeTwoCarat",
  },
  {
    Header: "SVS",
    accessor: (d) => d.svs ? d.svs ? 'Yes' : 'No' : null,
  },
  {
    Header: "Tracer",
    accessor: (d) => d.tracer ? d.tracer ? 'Yes' : 'No' : null,
  },
  {
    Header: "Diavik",
    accessor: (d) => d.diavik ? d.diavik ? 'Yes' : 'No' : null,
  },
];