import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/icons/solitaire_planet_logo-remove.png";
import { AiOutlineShopping } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import { IoPersonCircle } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import "./fontStyle"

const NotNavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('solitaireKey')));

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
 
  const menuRef = useRef(null);
  
  useEffect(() => {
    console.log(key)
  }, [key])

  const closeMenu = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <nav
      className="flex items-center justify-between p-4 bg-gray-800 text-white"
      style={{ backgroundColor: "#010519" }}
    >
      <div className="flex items-center">
        <img src={logo} alt="Company" className="mr-4 sm:w-48 w-24" />
      </div>

      {/* Navigation links for larger screens */}
      <div className="hidden lg:flex items-center space-x-4 gap-x-6">
        <Link
          to="/"
          className={`hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795] ${
            location.pathname === "/" ? "border-b-2 border-[#E4A795] text-[#E4A795] " : ""
          }`}
        >
          Home
        </Link>
        <Link
      to="/about"
      className={`hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795] ${
        location.pathname === "/about" ? "border-b-2 border-[#E4A795] text-[#E4A795] " : ''
      }`}
    >
      About Us
    </Link>
        <Link
          to="/diamonds"
          className={`hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795] ${
            location.pathname === "/diamonds" ? "border-b-2 border-[#E4A795] text-[#E4A795] " : ""
          }`}
        >
          Diamonds
        </Link>
        <Link
              to="https://drive.google.com/file/d/1dP7UbQP2h5ex8W9ipa6MRyNxIKPz-xca/view?usp=drive_link"
              target="_blank"
              className="hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
            >
              Jewels
            </Link>
            <Link
          to="/special"
          className={`hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795] ${
            location.pathname === "/special" ? "text-[#E4A795] " : ""
          }`}
        >
          Special
        </Link>
        <Link
          to="/contact"
          refresh="true"
          className={` hover:text-white border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795] ${
            location.pathname === "/contact" ? "border-b-2 border-[#E4A795] text-[#E4A795]" : ""
          }`}
        >
          Contact
        </Link>
          <Link
          to="/signin"
          refresh="true"
          className="bg-[#E4A795] text-black px-4 py-2 rounded cursor-pointer transition ease-in-out delay-150 bg--[#E4A795] hover:-translate-y-1 hover:scale-110 hover:bg--[#E4A795] duration-300"
        >
          Login & Signup
        </Link>
      </div>

      {/* Navigation links for smaller screens */}
      <div className="lg:hidden" ref={menuRef}>
        <button className="text-white focus:outline-none" onClick={toggleMenu}>
          {/* <span className="w-24 h-24">
          ☰
          </span> */}
          <GiHamburgerMenu size={24}/>
        </button>
        {menuOpen && (
          <div className="absolute top-0 left-0 bg-gray-800 p-4 space-y-4 w-full">
            <Link
              to="/"
              className={`block text-white ${
                location.pathname === "/" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`block text-white ${
                location.pathname === "/about" ? "border-b-2 border-white" : ""
              }`}
              onClick={closeMenu}
            >
              About Us
            </Link>
            <Link
              to="/diamonds"
              className={`block text-white ${
                location.pathname === "/diamonds"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Diamonds
            </Link>
            <Link
              to="https://drive.google.com/file/d/1dP7UbQP2h5ex8W9ipa6MRyNxIKPz-xca/view?usp=drive_link"
              target="_blank"
              onClick={closeMenu}
              className="block text-white"
            >
              Jewels
            </Link>
            <Link
              to="/diamonds"
              className={`block text-white ${
                location.pathname === "/special"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Special
            </Link>
            <Link
              to="/contact"
              className={`block text-white ${
                location.pathname === "/contact"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={closeMenu}
            >
              Contact
            </Link>
            {/* <span className="icon w-8 text-white">
              <AiOutlineShopping width={"24px"} />
            </span> */}
            <div
              className="border  bg-[#E4A795] text-black px-4 py-2 rounded hover:bg-red-300"
              // onClick={toggleLogin}
            >
              <a href="/signin">
              Login & Signup
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NotNavigationBar;
