import React, { useEffect, useState } from "react";
import About_1 from "../assets/icons/About_1.png";
import About_2 from "../assets/icons/About_2.png";
import About_3 from "../assets/icons/About_3.png";
import About_4 from "../assets/icons/About_4.png";
import About_5 from "../assets/icons/About_5.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaArrowRight } from "react-icons/fa";
import { useAuth } from "../auth/authContext";
import "../components/fontStyle";

const About = () => {
  const { setAuth } = useAuth();
  const [showButton, setShowButton] = useState(true);
  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("solitaireKey"))))
      setAuth(true);
    AOS.init({ duration: 1000 }); //customize the duration and other options
  }, []);

  const handleScroll = () => {
    const mainDiv = document.getElementById("mainDiv");
    const scrollPosition = window.scrollY + window.innerHeight;

    if (mainDiv && scrollPosition >= mainDiv.offsetHeight) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pb-8" style={{ backgroundColor: "#010519" }}>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
        `}
      </style>
      <style>
        {`
         @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
        `}
      </style>
      <div >
      <div id="mainDiv">
        {/* <div className="lg:justify-center text-Black lg:text-start lg:ml-40 text-center font-Belleza text-6xl py-8 font-normal"> */}
        <div className="lg:justify-center text-Black md:text-start lg:ml-32 text-center font-Belleza text-6xl py-8 font-normal">
          <h2 className="text-white" style={{ fontFamily: "Belleza" }}>
            About Us
          </h2>
        </div>
        
          <div className="flex flex-col md:flex-row lg:justify-center my-4">
            <div className="md:w-1/3 w-full md:order-2">
              <img
                src={About_1}
                alt="Your Image"
                className="w-full md:w-3/3 md:order-1 mb-8"
                data-aos="fade-up"
              />
            </div>
            <div
              className="text-center md:text-justify w-full md:w-1/2 p-4 md:order-1 max-h-fit h-full flex flex-col justify-between md:mt-10"
              data-aos="fade-up"
            >
              <p
                className="text-4xl font-bold mb-4 text-2xl"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Discover Solitaire Planet: A Symphony of Elegance and Expertise
              </p>
              <p className="text-2xl font-normal text-white leading-normal md:text-justify font-sans">
                At Solitaire Planet, we weave dreams into diamonds, crafting
                extraordinary pieces that transcend time. As natural diamond
                dealers, our journey is a testament to a decade-long commitment
                to excellence, from the heart of India to the vibrant landscapes
                of New York, USA.
              </p>
            </div>
          </div>
        {showButton && (
          <div className="flex justify-center left-[35%] my-4 mx-auto md:fixed md:bottom-5 md:z-10">
            <a
              href="/diamonds"
              className="px-8 py-4 bg-[#E4A795] relative group flex items-center rounded-md text-xl md:font-base font-medium pr-8"
            >
              <span className="group-hover:translate-x-1  transition-transform md:ml-5">
                Check Out Our Diamonds
              </span>
              <FaArrowRight
                size={24}
                className="hidden md:flex arrow-icon opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer duration-500 ml-2"
              />
            </a>
          </div>
        )}

          <div className="flex flex-col md:flex-row lg:justify-center  my-12">
            <img
              src={About_2}
              alt="Your Image"
              className="w-full md:w-1/3 md:order-1 mb-8"
              data-aos="fade-up"
            />
            <div
              className="text-center md:text-justify w-full md:w-1/2 p-4 md:order-2 max-h-fit h-full flex flex-col justify-between md:mt-10"
              data-aos="fade-up"
            >
              <p
                className="text-4xl font-bold mb-4 text-2xl"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Our Roots in India: 10+ Years of Expertise
              </p>
              <p
                className="text-2xl font-normal text-white leading-normal font-sans"
                // style={{ fontFamily: "Belleza" }}
              >
                With a heritage grounded in the rich traditions of India's
                diamond industry, Solitaire Planet emerged as a beacon of
                craftsmanship and integrity. For over a decade, we have honed
                our skills, creating diamonds that embody not only exceptional
                brilliance but also the cultural richness from which we draw
                inspiration.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row lg:justify-center my-12">
            <img
              src={About_3}
              alt="Your Image"
              className="w-full md:w-1/3 md:order-2 mb-8"
              data-aos="fade-up"
            />
            <div
              className="text-center md:text-justify w-full md:w-1/2 p-4 md:order-1 max-h-fit h-full flex flex-col justify-between md:mt-10"
              data-aos="fade-up"
            >
              <p
                className="text-4xl font-bold mb-4 text-2xl"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Now in New York, USA: Where Dreams Take Shape
              </p>
              <p className="text-2xl font-normal text-white leading-normal font-sans">
                Solitaire Planet proudly calls New York home, infusing the
                city's energy into every facet of our diamonds. Nestled in the
                heart of the USA, our atelier in New York is a fusion of
                heritage and contemporary flair. Here, we continue our legacy,
                creating diamonds that sparkle against the backdrop of the city
                that never sleeps.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row lg:justify-center  my-12">
            <img
              src={About_4}
              alt="Your Image"
              className="w-full md:w-1/3 md:order-1 mb-8"
              data-aos="fade-up"
            />
            <div
              className="text-center md:text-justify w-full md:w-1/2 p-4 md:order-2 max-h-fit h-full flex flex-col justify-between md:mt-10"
              data-aos="fade-up"
            >
              <p
                className="text-4xl font-bold mb-4 text-2xl"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Now in New York, USA: Where Dreams Take Shape
              </p>
              <p className="text-2xl font-normal text-white font-sans">
                Solitaire Planet proudly calls New York home, infusing the
                city's energy into every facet of our diamonds. Nestled in the
                heart of the USA, our atelier in New York is a fusion of
                heritage and contemporary flair. Here, we continue our legacy,
                creating diamonds that sparkle against the backdrop of the city
                that never sleeps.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row lg:justify-center  my-12">
            <img
              src={About_5}
              alt="Your Image"
              className="w-full md:w-1/3 md:order-2 mb-8"
              data-aos="fade-up"
            />
            <div
              className="text-center md:text-justify w-full md:w-1/2 p-4 md:order-1 max-h-fit h-full flex flex-col justify-between md:mt-10"
              data-aos="fade-up"
            >
              <p
                className="text-4xl font-bold mb-4 text-2xl leading-normal"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Trusted by Many, Loved by All
              </p>
              <p className="text-2xl font-normal text-white font-sans">
                In our journey to the USA, we have garnered the trust of a
                discerning clientele and the love of happy customers. Solitaire
                Planet stands as a symbol of reliability, transparency, and an
                unwavering commitment to delivering diamonds of unparalleled
                quality.
              </p>
            </div>
          </div>
        </div>
       
        <div data-aos="fade-up">
          <p
            className="text-4xl text-center font-normal  text-white py-12"
            style={{ fontFamily: "Belleza" }}
          >
            Our Pledge to You
          </p>
          <div className="flex flex-col lg:flex-row lg:justify-center  lg:gap-x-5 gap-y-12 mt-5 mx-2 md:mx-10 leading-5">
            <div
              className="gap-y-6 text-xl text-center font-normal max-h-fit h-full flex flex-col justify-between mx-4 sm:mx-auto my-auto"
              style={{ fontFamily: "Belleza" }}
              data-aos="fade-up"
            >
              <p className="text-[#E4A795] text-3xl">Uncompromising Quality</p>
              <p className=" text-white font-sans">
                Solitaire Planet diamonds are synonymous with uncompromising
                quality. Each stone undergoes rigorous testing and meets the
                highest industry standards.
              </p>
            </div>
            <div
              className="gap-y-6 text-xl text-center font-normal max-h-fit h-full flex flex-col justify-between mx-4 sm:mx-auto my-auto"
              style={{ fontFamily: "Belleza" }}
              data-aos="fade-up"
            >
              <p className="text-[#E4A795] text-3xl">Personalised Services</p>
              <p className=" text-white font-sans">
                We believe in a personalized approach, understanding your unique
                preferences to create bespoke pieces that resonate with your
                individual style
              </p>
            </div>
            <div
              className="gap-y-6 text-xl text-center font-normal max-h-fit h-full flex flex-col justify-between mx-4 sm:mx-auto my-auto"
              style={{ fontFamily: "Belleza" }}
              data-aos="fade-up"
            >
              <p className="text-[#E4A795] text-3xl">Ethical Sourcing</p>
              <p className=" text-white font-sans">
                Our diamonds are sourced responsibly, ensuring ethical practices
                from mine to market. We prioritize sustainability and integrity
                in every facet of our business.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
