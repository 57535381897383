import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../auth/authContext";
import { classNames } from "../helper/classname";
import { IoIosClose } from "react-icons/io";
import Table from "../components/tables/table";
import { catalogue_column } from "../components/tables/tableheader";
import app_api from "../config";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modals";
import { BsPlayCircle } from "react-icons/bs";
import { Formik } from 'formik'
import Select from 'react-select'
import moment from 'moment'

const diamondShape = [
  {
    value: "Round",
    name: "Round",
    type: "round",
    image: "round.svg",
  },
  {
    value: "Emerald",
    name: "Emerald",
    type: "fancy",
    image: "emerald.svg",
  },
  {
    value: "Oval",
    name: "Oval",
    type: "fancy",
    image: "oval.svg",
  },
  {
    value: "Radiant",
    name: "Radiant",
    type: "fancy",
    image: "radiant.svg",
  },
  {
    value: "Pear",
    name: "Pear",
    type: "fancy",
    image: "pear.svg",
  },
  {
    value: "Cushion All",
    name: "Cushion All",
    type: "fancy",
    image: "cushion_all.svg",
  },
  {
    value: "Cushion",
    name: "Cushion",
    type: "fancy",
    image: "cushion.svg",
  },
  {
    value: "Cushion M",
    name: "Cushion M",
    type: "fancy",
    image: "cushion_m.svg",
  },
  {
    value: "Cushion B",
    name: "Cushion B",
    type: "fancy",
    image: "cushion_b.svg",
  },
  {
    value: "Princess",
    name: "Princess",
    type: "fancy",
    image: "princess.svg",
  },
  {
    value: "Cushion M",
    name: "Cushion M",
    type: "fancy",
    image: "cushion_m.svg",
  },
  {
    value: "Heart",
    name: "Heart",
    type: "fancy",
    image: "heart.svg",
  },
  {
    value: "Marquise",
    name: "Marquise",
    type: "fancy",
    image: "marquise.svg",
  },
  {
    value: "Asscher & Sq. Emerald",
    name: "Asscher & Sq. Emerald",
    type: "fancy",
    image: "asscher_sq_emerald.svg",
  },
  {
    value: "Baguette",
    name: "Baguette",
    type: "fancy",
    image: "baguette.svg",
  },
  {
    value: "Taper",
    name: "Taper",
    type: "fancy",
    image: "taper.svg",
  },
  {
    value: "Other",
    name: "Other",
    type: "fancy",
    image: "other.svg",
  },
];

const diamondCaratRange = [
  {
    value: "0.01-0.22",
    name: "0.01-0.22",
  },
  {
    value: "0.23-0.29",
    name: "0.23-0.29",
  },
  {
    value: "0.30-0.34",
    name: "0.30-0.34",
  },
  {
    value: "0.35-0.39",
    name: "0.35-0.39",
  },
  {
    value: "0.40-0.49",
    name: "0.40-0.49",
  },
  {
    value: "0.50-0.59",
    name: "0.50-0.59",
  },
  {
    value: "0.60-0.69",
    name: "0.60-0.69",
  },
  {
    value: "0.70-0.79",
    name: "0.70-0.79",
  },
  {
    value: "0.80-0.89",
    name: "0.80-0.89",
  },
  {
    value: "0.90-0.94",
    name: "0.90-0.94",
  },
  {
    value: "0.95-0.99",
    name: "0.95-0.99",
  },
  {
    value: "1.10-1.19",
    name: "1.10-1.19",
  },
  {
    value: "1.20-1.29",
    name: "1.20-1.29",
  },
  {
    value: "1.30-1.39",
    name: "1.30-1.39",
  },
  {
    value: "1.40-1.49",
    name: "1.40-1.49",
  },
  {
    value: "1.50-1.69",
    name: "1.50-1.69",
  },
  {
    value: "1.70-1.99",
    name: "1.70-1.99",
  },
  {
    value: "2.00-2.49",
    name: "2.00-2.49",
  },
  {
    value: "2.50-2.99",
    name: "2.50-2.99",
  },
  {
    value: "3.00-3.99",
    name: "3.00-3.99",
  },
  {
    value: "4.00-4.99",
    name: "4.00-4.99",
  },
  {
    value: "5.00-5.99",
    name: "5.00-5.99",
  },
  {
    value: "6+",
    name: "6+",
  },
];

const diamondColorRange = [
  {
    value: "D",
    name: "D",
    type: "color",
  },
  {
    value: "E",
    name: "E",
    type: "color",
  },
  {
    value: "F",
    name: "F",
    type: "color",
  },
  {
    value: "G",
    name: "G",
    type: "color",
  },
  {
    value: "H",
    name: "H",
    type: "color",
  },
  {
    value: "I",
    name: "I",
    type: "color",
  },
  {
    value: "J",
    name: "J",
    type: "color",
  },
  {
    value: "K",
    name: "K",
    type: "color",
  },
  {
    value: "L",
    name: "L",
    type: "color",
  },
  {
    value: "M",
    name: "M",
    type: "color",
  },
  {
    value: "N-",
    name: "N-",
    type: "color",
  },
  {
    value: "DEF",
    name: "DEF",
    type: "group",
  },
  {
    value: "GH",
    name: "GH",
    type: "group",
  },
  {
    value: "IJK",
    name: "IJK",
    type: "group",
  },
  {
    value: "LMN-",
    name: "LMN-",
    type: "group",
  },
];

const diamondClarityRange = [
  {
    value: "FL",
    name: "FL",
    type: "clarity",
  },
  {
    value: "IF",
    name: "IF",
    type: "clarity",
  },
  {
    value: "VVS1",
    name: "VVS1",
    type: "clarity",
  },
  {
    value: "VVS2",
    name: "VVS2",
    type: "clarity",
  },
  {
    value: "VS1",
    name: "VS1",
    type: "clarity",
  },
  {
    value: "VS2",
    name: "VS2",
    type: "clarity",
  },
  {
    value: "SI1",
    name: "SI1",
    type: "clarity",
  },
  {
    value: "SI2",
    name: "SI2",
    type: "clarity",
  },
  {
    value: "SI3",
    name: "SI3",
    type: "clarity",
  },
  {
    value: "I1",
    name: "I1",
    type: "clarity",
  },
  {
    value: "I2",
    name: "I2",
    type: "clarity",
  },
  {
    value: "I3",
    name: "I3",
    type: "clarity",
  },
  {
    value: "FL-IF",
    name: "FL-IF",
    type: "clarity_group",
  },
  {
    value: "VVS",
    name: "VVS",
    type: "clarity_group",
  },
  {
    value: "VS",
    name: "VS",
    type: "clarity_group",
  },
  {
    value: "SI",
    name: "SI",
    type: "clarity_group",
  },
  {
    value: "I",
    name: "I",
    type: "clarity_group",
  },
];

const diamondColorShade = [
  {
    value: "NONE",
    name: "NONE",
    type: "clarity",
  },
  {
    value: "VLB",
    name: "VLB",
    type: "clarity",
  },
  {
    value: "VYB",
    name: "VYB",
    type: "clarity",
  },
  {
    value: "VGN",
    name: "VGN",
    type: "clarity",
  },
  {
    value: "LBR",
    name: "LBR",
    type: "clarity",
  },
  {
    value: "BR",
    name: "BR",
    type: "clarity",
  },
  {
    value: "OTHER",
    name: "OTHER",
    type: "clarity",
  },
  {
    value: "FLGB",
    name: "FLGB",
    type: "clarity",
  },
];

const diamondroundMake = [
  {
    value: "3EX",
    name: "3EX",
  },
  {
    value: "3EX-NONE",
    name: "3EX-NONE",
  },
];

const diamondFancyMake = [
  {
    value: "2EX",
    name: "2EX",
  },
  {
    value: "2EX-NONE",
    name: "2EX-NONE",
  },
];

const DiamondCut = [
  {
    value: "EX",
    name: "EX",
  },
  {
    value: "VG",
    name: "VG",
  },
  {
    value: "GD",
    name: "GD",
  },
  {
    value: "FR",
    name: "FR",
  },
  {
    value: "PR",
    name: "PR",
  },
];

const DiamondPolish = [
  {
    value: "EX",
    name: "EX",
  },
  {
    value: "VG",
    name: "VG",
  },
  {
    value: "GD",
    name: "GD",
  },
  {
    value: "FR",
    name: "FR",
  },
  {
    value: "PR",
    name: "PR",
  },
];

const DiamondSymmetry = [
  {
    value: "EX",
    name: "EX",
  },
  {
    value: "VG",
    name: "VG",
  },
  {
    value: "GD",
    name: "GD",
  },
  {
    value: "FR",
    name: "FR",
  },
  {
    value: "PR",
    name: "PR",
  },
];

const DiamondFluorescence = [
  {
    value: "NON",
    name: "NON",
  },
  {
    value: "FNT",
    name: "FNT",
  },
  {
    value: "MED",
    name: "MED",
  },
  {
    value: "STG",
    name: "STG",
  },
  {
    value: "VST",
    name: "VST",
  },
  {
    value: "VSL",
    name: "VSL",
  },
  {
    value: "SL",
    name: "SL",
  },
];

const DiamondLab = [
  {
    value: "GIA",
    name: "GIA",
  },
  {
    value: "VLB",
    name: "AGC",
  },
  {
    value: "IGI",
    name: "IGI",
  },
  {
    value: "HRD",
    name: "HRD",
  },
  {
    value: "NON CERT",
    name: "NON CERT",
  },
  {
    value: "OTHER",
    name: "OTHER",
  },
  {
    value: "XALL",
    name: "XALL",
  },
];

const HNA = [
  {
    value: "NONE",
    name: "NONE",
  },
  {
    value: "VLB",
    name: "VLB",
  },
  {
    value: "VYB",
    name: "VYB",
  },
  {
    value: "VGN",
    name: "VGN",
  },
  {
    value: "LBR",
    name: "LBR",
  },
  {
    value: "BR",
    name: "BR",
  },
  {
    value: "OTHER",
    name: "OTHER",
  },
  {
    value: "FLGB",
    name: "FLGB",
  },
];

const Diamonadavailability = [
  {
    value: "AVAILABLE",
    name: "AVAILABLE",
  },
  {
    value: "MEMO",
    name: "MEMO",
  },
  {
    value: "SHOW",
    name: "SHOW",
  },
];

const DiamondStatus = [
  {
    value: "NEW ARRIVAL",
    name: "NEW ARRIVAL",
  },
  {
    value: "UPCOMING",
    name: "UPCOMING",
  },
  {
    value: "NO BGM",
    name: "NO BGM",
  },
  {
    value: "EYE CLEAN",
    name: "EYE CLEAN",
  },
  {
    value: "MEET THE ARTISAN (MTA)",
    name: "MEET THE ARTISAN (MTA)",
  },
];

const DiamondTrade = [
  {
    value: "CANADAMARK",
    name: "CANADAMARK",
  },
  {
    value: "SVS",
    name: "SVS",
  },
  {
    value: "TRADE (DTC)",
    name: "TRADE (DTC)",
  },
  {
    value: "DIAVIK (GIA)",
    name: "DIAVIK (GIA)",
  },
  {
    value: "COO",
    name: "COO",
  },
  {
    value: "DOR",
    name: "DOR",
  },
  {
    value: "TYPE IIA",
    name: "TYPE IIA",
  },
  {
    value: "XRAY",
    name: "XRAY",
  },
];

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data:[],
}

const initialHoldState = {
  type: '',
  state: false,
  index: null,
  id: '',
  data: {
    comments: '',
    holdType: '',
    holdtill: '',
    user_id: '',
  },
}

const holdTypeOption = [
  {
    value: "Long Hold",
    name: "Long Hold",
    time: 24,
  },
  {
    value: "Short Hold",
    name: "Short Hold",
    time: 1,
  },
]

const Special = () => {
  const { setAuth } = useAuth();
  const [diamondShapeState, setDiamondShapeState] = useState("");
  const [diamondColorState, setDiamondColorState] = useState("color");
  const [diamondClarityState, setDiamondClarityState] = useState("clarity");
  const [diamondShapePayload, setDiamondShapePayload] = useState([]);
  const [diamondCaratRangePayload, setDiamondCaratRangePayload] = useState([]);
  const [diamondColorPayload, setDiamondColorPayload] = useState([]);
  const [diamondClarityPayload, setDiamondClarityPayload] = useState([]);
  const [diamondColorShadePayload, setDiamondColorShadePayload] = useState([]);
  const [diamondRoundMakePayload, setDiamondRoundMakePayload] = useState([]);
  const [diamondFancyMakePayload, setDiamondFancyMakePayload] = useState([]);
  const [diamondCutPayload, setDiamondCutPayload] = useState([]);
  const [diamondPolishPayload, setDiamondPolishPayload] = useState([]);
  const [diamondSymmetryPayload, setDiamondSymmetryPayload] = useState([]);
  const [diamondFluorescencePayload, setDiamondFluorescencePayload] = useState(
    []
  );
  const [diamondLabPayload, setDiamondLabPayload] = useState([]);
  const [diamondHNAPayload, setDiamondHNAPayload] = useState([]);
  const [diamondAvailability, setDiamondAvailability] = useState([]);
  const [diamondStatus, setDiamondStatus] = useState([]);
  const [diamondTradePayload, setDiamondTradePayload] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [key, setKey] = useState(
    JSON.parse(localStorage.getItem("solitaireKey"))
  );
  const [fromCarat, setFromCarat] = useState();
  const [toCarat, setToCarat] = useState();
  const [pairNo, setPairNo] = useState();
  const [discfrom, setDiscFrom] = useState();
  const [discto, setDiscto] = useState();
  const [Pricefrom, setPriceFrom] = useState();
  const [Priceto, setPriceto] = useState();
  const [Amountfrom, setAmountFrom] = useState();
  const [Amountto, setAmountto] = useState();
  const [addCaratPayload, setAddCaratPayload] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [specialDiamond, setSpecialDiamond] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");

  const navigate = useNavigate()
  const [compareModal, setCompareModal] = useState(initialModalState);
  const [modal, setModal] = useState(initialHoldState);
  const login =  JSON.parse(localStorage.getItem("solitaireKey"))
  const userNavigation = [
    { name: "Without Price", value: "without" },
    { name: "With Price", value: "with" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 42,
      minHeight: 15,
      borderRadius: '8px',
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? '#E4A795' : '#E4A795',
      '&:hover': {
        borderColor: state.isFocused ? '#E4A795' : '#E4A795'}
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#E4A795' : '#E4A795',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#E4A795' : '#E4A795',
      backgroundColor: state.isSelected ? '#515666' : '',
      borderRadius: '5%',

    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5%',
      backgroundColor:'#515666'
    }),
  };

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev);
  };

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev);
  };

  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("solitaireKey"))))
      setAuth(true);
  });

  useEffect(() => {
    getDiamondData();
  }, [page, totalPages, limit]);

  const getDiamondData = () => {
    try {
      let payload = {
        shape: diamondShapePayload.map((e) => e.value),
        color: diamondColorPayload.map((e) => e.value),
        clarity: diamondClarityPayload.map((e) => e.value),
        shade: diamondColorShadePayload.map((e) => e.value),
        cut: diamondCutPayload.map((e) => e.value),
        polish: diamondPolishPayload.map((e) => e.value),
        symmetry: diamondSymmetryPayload.map((e) => e.value),
        fluorescence: diamondFluorescencePayload.map((e) => e.value),
        lab: diamondLabPayload.map((e) => e.value),
        heartArrow: diamondHNAPayload.map((e) => e.value),
        carat: diamondCaratRangePayload.map((e) => e.value),
        // fancy: diamondFancyMakePayload.map(e => e.value),
        // round: diamondRoundMakePayload.map(e => e.value),
        availability: diamondAvailability.map((e) => e.value),
        status: diamondStatus.map((e) => e.value),
        pairNo: pairNo,
        fromDiscount: discfrom,
        toDisccount: discto,
        fromAmount: Amountfrom,
        toAmount: Amountto,
        fromPrice: Pricefrom,
        toPrice: Priceto,
      };
      app_api
        .post(
          `search-diamonds/special/search?size=${limit}&page=${page}`,
          payload
        )
        .then((res) => {
          let data = res.data.data.data;
          data = data.map((element) =>
            Object.assign(element, { checked: false })
          );
          setSpecialDiamond(data);
          setCount(res.data.data.count);
          setTotalPages(res.data.data.count / limit);
          setLoading(false);
          // setSearchValue(true)
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const SearchValue = (boolean) => {
    getDiamondData();
    setSearchValue(boolean);
    setLoading(boolean);
  };

  const addCarat = (value1, value2) => {
    let data = Array.from(addCaratPayload);
    data.push({
      from: value1,
      to: value2,
    });
    setAddCaratPayload(data);
  };

  const removefromAddCarat = (i) => {
    let data = Array.from(addCaratPayload);
    data.splice(i, 1);
    setAddCaratPayload(data);
  };

  const setPayloadForDiamond = (value) => {
    let data = Array.from(diamondShapePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.type == value.type
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondShapePayload(data);
  };

  const setPayloadForCaratRange = (value) => {
    let data = Array.from(diamondCaratRangePayload);
    let index = data.findIndex(
      (e) => e.value == value.value && e.name == value.name
    );
    console.log(data);
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    console.log(data);
    setDiamondCaratRangePayload(data);
  };

  const setPayloadForColor = (value) => {
    let data = Array.from(diamondColorPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.type == value.type
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondColorPayload(data);
  };

  const setPayloadForClarity = (value) => {
    let data = Array.from(diamondClarityPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.type == value.type
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondClarityPayload(data);
  };

  const setPayloadForColorShade = (value) => {
    let data = Array.from(diamondColorShadePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.type == value.type
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondColorShadePayload(data);
  };

  const setPayloadForRoundMake = (value) => {
    let data = Array.from(diamondRoundMakePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondRoundMakePayload(data);
  };

  const setPayloadForFancyMake = (value) => {
    let data = Array.from(diamondFancyMakePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondFancyMakePayload(data);
  };

  const setPayloadForCut = (value) => {
    let data = Array.from(diamondCutPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondCutPayload(data);
  };

  const setPayloadForPolish = (value) => {
    let data = Array.from(diamondPolishPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondPolishPayload(data);
  };

  const setPayloadForSymmetry = (value) => {
    let data = Array.from(diamondSymmetryPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondSymmetryPayload(data);
  };

  const setPayloadForFluorescence = (value) => {
    let data = Array.from(diamondFluorescencePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondFluorescencePayload(data);
  };

  const setPayloadForLab = (value) => {
    let data = Array.from(diamondLabPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondLabPayload(data);
  };

  const setPayloadForHNA = (value) => {
    let data = Array.from(diamondHNAPayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondHNAPayload(data);
  };

  const setPayloadAvaiability = (value) => {
    let data = Array.from(diamondAvailability);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondAvailability(data);
  };

  const setPayloadDiamondStatus = (value) => {
    let data = Array.from(diamondStatus);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondStatus(data);
  };

  const setPayloadDiamondTrade = (value) => {
    let data = Array.from(diamondTradePayload);
    let index = data.findIndex(
      (e) => e.name == value.name && e.value == value.value
    );
    if (index >= 0) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }
    setDiamondTradePayload(data);
  };

  const renderShape = (e) => {
    if (diamondShapePayload.some((d) => d.name == e.name && d.type == e.type)) {
      return (
        <>
          <div
            className="bg-[#E4A795] border-[#E4A795] h-28 md:h-32 lg:h-40 border border-white p-4 text-white rounded-md flex flex-col justify-center items-center hover:bg-[#E4A795] hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => {
              setPayloadForDiamond(e);
            }}
          >
            <img
              src={`${process.env.REACT_APP_BACKEND_UPLOAD_URL}uploads/images/${e.image}`}
              className="w-20 h-20"
            />
            <p className="text-sm text-center mt-2">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <>
            <div
              className="h-28 md:h-32 lg:h-40 border border-white p-4 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
              onClick={() => {
                setPayloadForDiamond(e);
              }}
            >
              <img
                src={`${process.env.REACT_APP_BACKEND_UPLOAD_URL}uploads/images/${e.image}`}
                className="w-20 h-20"
              />
              <p className="text-sm text-center mt-2">{e.name}</p>
            </div>
          </>
        </>
      );
    }
  };

  const renderCaratRange = (e) => {
    if (
      diamondCaratRangePayload.some(
        (d) => d.name == e.name && d.value == e.value
      )
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => {
              setPayloadForCaratRange(e);
            }}
          >
            <p className="text-md text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => {
              setPayloadForCaratRange(e);
            }}
          >
            <p className="text-md text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderColorRange = (e) => {
    if (diamondColorPayload.some((d) => d.name == e.name && d.type == e.type)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForColor(e)}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForColor(e)}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    }
  };

  const renderColorShadeRange = (e) => {
    if (diamondColorShadePayload.some((d) => d.name == e.name && d.type == e.type)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:bg-[#E4A795] hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => {
              setPayloadForColorShade(e);
            }}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:bg-[#E4A795] hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => {
              setPayloadForColorShade(e);
            }}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    }
  };
  const renderClarityRange = (e) => {
    if (
      diamondClarityPayload.some((d) => d.name == e.name && d.type == e.type)
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForClarity(e)}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-18 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForClarity(e)}
          >
            <p className="text-sm md:text-md lg:text-md text-center">
              {e.name}
            </p>
          </div>
        </>
      );
    }
  };

  const renderRoundMake = (e) => {
    if (
      diamondRoundMakePayload.some(
        (d) => d.name == e.name && d.value == e.value
      )
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForRoundMake(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForRoundMake(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderFancyMake = (e) => {
    if (
      diamondFancyMakePayload.some(
        (d) => d.name == e.name && d.value == e.value
      )
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForFancyMake(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForFancyMake(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderCutMake = (e) => {
    if (diamondCutPayload.some((d) => d.name == e.name && d.value == e.value)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForCut(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-10 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForCut(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderPolishMake = (e) => {
    if (
      diamondPolishPayload.some((d) => d.name == e.name && d.value == e.value)
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForPolish(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-10 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForPolish(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderSymmetryMake = (e) => {
    if (
      diamondSymmetryPayload.some((d) => d.name == e.name && d.value == e.value)
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForSymmetry(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-10 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForSymmetry(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderFluorescenceMake = (e) => {
    if (
      diamondFluorescencePayload.some(
        (d) => d.name == e.name && d.value == e.value
      )
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForFluorescence(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-10 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForFluorescence(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderLabMake = (e) => {
    if (diamondLabPayload.some((d) => d.name == e.name && d.value == e.value)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForLab(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForLab(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderHNA = (e) => {
    if (diamondHNAPayload.some((d) => d.name == e.name && d.value == e.value)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForHNA(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadForHNA(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const Availability = (e) => {
    if (
      diamondAvailability.some((d) => d.name == e.name && d.value == e.value)
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadAvaiability(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-20 md:w-20 lg:w-20 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadAvaiability(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderStatus = (e) => {
    if (diamondStatus.some((d) => d.name == e.name && d.value == e.value)) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadDiamondStatus(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-24 md:w-24 lg:w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadDiamondStatus(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const renderTrade = (e) => {
    if (
      diamondTradePayload.some((d) => d.name == e.name && d.value == e.value)
    ) {
      return (
        <>
          <div
            className="bg-[#E4A795] w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadDiamondTrade(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="w-24 md:w-24 lg:w-24 border border-stone-500 p-1 text-white rounded-md flex flex-col justify-center items-center hover:border-[#E4A795] duration-500 cursor-pointer"
            onClick={() => setPayloadDiamondTrade(e)}
          >
            <p className="text-[13px] text-center">{e.name}</p>
          </div>
        </>
      );
    }
  };

  const onCheckBox = (id, index) => {
    let special = Array.from(specialDiamond);
    let finalData;
    let data = Array.from(selected);
    const foundObject = data.find((obj) => obj.id == id);
    if (foundObject) {
      let indexOfData = data.findIndex((e) => e.id == id);
      data.splice(indexOfData, 1);
      finalData = special.map((obj) => {
        if (obj.id == id) {
          if (obj.hasOwnProperty("checked")) {
            return { ...obj, ["checked"]: false };
          }
          return obj;
        } else {
          return obj;
        }
      });
    } else {
      finalData = special.map((obj) => {
        if (obj.id == id) {
          if (obj.hasOwnProperty("checked")) {
            return { ...obj, ["checked"]: true };
          }
          return obj;
        } else {
          return obj;
        }
      });
      data.push(special[index]);
    }
    setSpecialDiamond(finalData);
    setSelected(data);
  };

  useEffect(()=> {
    console.log("New: ", selected)
  },[selected])

  const copyToClipboard = (value) => {
    if(selected.length > 0)
    {
      let data = selected.map((e) => {
        if(value.value == 'with')
        {
          return (
            {
              "Stock#": e.packetNo,
              "Image": `https://views.diadna.com/diamondVideoview/${e.packetNo}`,
              "Video": `https://views.diadna.com/diamondfullview/${e.packetNo}`,
              "Cert":  `https://www.gia.edu/report-check?reportno=${e.packetNo}`,
              "DNA": `https://views.diadna.com/dna/${e.packetNo}`,
              "Exceptional": e.shape+"-"+e.color+"-"+e.clarity+"-"+e.cut+"-"+e.polish+"-"+e.symmetry+"-"+e.fluorescence+"-"+e.lab,
              "Price": e.carat+" -"+e.discount+" P/CT"+e.price+" Total: $"+(e.price * e.carat).toFixed(2)
            }
          )
        }
        else
        {
          return (
            {
              "Stock#": e.packetNo,
              "Image": `https://views.diadna.com/diamondVideoview/${e.packetNo}`,
              "Video": `https://views.diadna.com/diamondfullview/${e.packetNo}`,
              "Cert":  `https://www.gia.edu/report-check?reportno=${e.packetNo}`,
              "DNA": `https://views.diadna.com/dna/${e.packetNo}`,
              "Exceptional": e.shape+"-"+e.color+"-"+e.clarity+"-"+e.cut+"-"+e.polish+"-"+e.symmetry+"-"+e.fluorescence+"-"+e.lab,
              "Carat": e.carat
            }
          )
        }
      })
      let text = data.map(obj => {
        return Object.keys(obj).map(key => `${key} ${obj[key]}`).join(',\n');
      }).join('\n\n\n');    
      let textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      toast.success('Details have been copied on your clipboard', 10)
    }
    else
    {
      toast.warning('Please select a diamond piece', 10)
    }
  };

  const compareStone = () => {
    if(selected.length === 2){
      if(!login || login == null){
        navigate("/signin")
      }else{
        setCompareModal((prev) => ({ ...prev, state: true, data: selected }));
      }
    }else{
      toast.warning('Please select 2 diamond pieces', 10)
    }
  }

  const holdData = () => {
    if(selected.length > 0){
      if(!login || login == null){
        navigate("/signin")
      }else{
        setModal((prev) => ({ ...prev, state: true,data:{
          ...prev.data,
          user_id: login.id,
        }}));
        
      }
    }else{
      toast.warning('Please select atleast 1 diamond piece', 10)
    }
  }

  const renderCompare = () => {
    const { state, data } = compareModal;
    return (
      <Modal
        open={state}
        setOpen={() => {
          setCompareModal((prev) => ({ ...prev, state: false }))
        }}
        title={"Compare Stone (2)"}
        handleClick={""}
      >
        <div className="py-2 text-white text-center text-xs">
          <div className="flex">
            <div className="flex flex-col gap-2 w-full border border-[#E4A795]">
              <p className="h-10 flex items-center justify-center">
                Diamond Video
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Stone No
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Lab
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Shape
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Carat
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Clarity
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Shade
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Rap ($)
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Discount %
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Price / Carat ($)
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Amount ($)
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Certificate No
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Fluorescence
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Cut
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Polish
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Symmetry
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Eye Clean
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Depth %
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Table %
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Length
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Width
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Depth
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Ratio
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Crown Angle
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Crown Height
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Pav Angle
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Pav Height
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Girdle
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                Culet
              </p>
              <hr className="w-full border-1 border-[#E4A795]" />
              <p>
                H&A
              </p>
            </div>
            {data.map((e) => {
              return (
                <div className="border border-[#E4A795] w-full flex flex-col gap-2">
                  <div className="h-10 flex justify-center items-center">
                    <a
                      href={`https://views.diadna.com/diamondfullview/${e.packetNo}`}
                      className="text-white flex justify-center"
                      size="default"
                      variant="outlined"
                      target="_blank"
                    >
                      <span className="flex gap-1 items-center">
                        <BsPlayCircle className="w-6" />
                        <span>Video</span>
                      </span>
                    </a>
                  </div >
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.packetNo}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.lab}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.shape}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.carat}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.clarity ? e.clarity : "-"}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.shade ? e.shade : "-"}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.rap}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.discount ? e.discount.toFixed(2) + ' %' : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.price ? '$ ' + e.price.toFixed(2) : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.amount ? '$ ' + e.amount.toFixed(2) : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    Certificate No
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.fluorescence}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.cut}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.polish}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.symmetry}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.eyeclean ? 'Yes' : 'No'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.depthper}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.tableper}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.length}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.width}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.depth}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.ratio}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.crownAngle ? e.crownAngle : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.crownHeight ? e.crownHeight : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.pavillionAngle ? e.pavillionAngle : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.pavillionHeight ? e.pavillionHeight : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.girdle ? e.girdle : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.culet ? e.culet : '-'}
                  </p>
                  <hr className="w-full border-1 border-[#E4A795]" />
                  <p>
                    {e.heartArrow ? e.heartArrow : '-'}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="w-full h-10 mt-12 flex justify-end items-center gap-4 mb-4">
          <button className="border-2 border-[#E4A795] px-2 py-3 text-[#E4A795] sm:text-lg text-base text-center rounded-md transition ease-in-out delay-150 bg--[#E4A795] hover:-translate-y-1 hover:scale-110 hover:bg--[#E4A795] duration-300"
            type="button"
            onClick={() => {
              setCompareModal((prev) => ({ ...prev, state: false }))
            }}
          >
            Close
          </button>

        </div>


      </Modal>
    );
  }
  
  const renderHold = () => {
    const { state, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {;
          const endTime = moment().add(values.holdtill, 'minutes').format();
          let payload ={
            user_id: values.user_id,
            comments: values.comments,
            holdType: values.holdType,
            holdtill: endTime,
            diamond:selected.map(e => e.id)
          }
          setSubmitting(true)
          app_api.post(`search-diamonds/hold`, payload)
          .then((res) => {
            console.log(res.data)
            setSubmitting(false)
            setModal(initialHoldState)
            getDiamondData()
            toast.success('Diamond Holded Successfully')
          })
          .catch((err) => {
            setSubmitting(false)
            toast.error('Something went wrong')
          })
        }}
      >
        {({
          handleSubmit,
          setValues,
          values,
          handleBlur,
          handleChange,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Modal
            title={'Hold Diamonds'}
            open={state}
            setOpen={() => {
              setModal(initialHoldState)
            }}
          >
            <form onSubmit={handleSubmit} noValidate className="px-6">
              <div className="mt-4 text-left gap-2 grid md:grid-cols-2 grid-cols-1">

                <div className="col-span-1">
                  <div className='flex'>
                    <label className='block text-md font-medium text-[#E4A795]' >Type </label>
                  </div>
                  <Select
                    name="holdType"
                    styles={customStyles}
                    placeholder='Enter Type... '
                    options={holdTypeOption?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.value,
                    }))}
                    value={values.holdType ? {
                      value: holdTypeOption?.find(
                        (e) => e.value == values.holdType
                      )?.value,
                      label: holdTypeOption?.find(
                        (e) => e.value == values.holdType
                      )?.name,
                    } : null
                    }
                    onChange={(option) => {
                      setValues((prev) => ({
                        ...prev,
                        holdType: option.value,
                        holdtill: option.time * 60,
                      }));
                    }}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                  {touched.holdType && (
                    <p className="text-red-700 mb-2 error_msg">
                      {errors.holdType}
                    </p>
                  )}
                </div>

                <div className='cols-span-1'>
                  <div className='flex'>
                    <label className='block text-md font-medium text-[#E4A795]' >Time Duration (in Minutes)</label>
                  </div>
                  <input
                    name="holdtill"
                    label="holdtill"
                    value={values.holdtill}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Enter Time Duration...'
                    className={`p-1 border rounded-md border-1 border-[#E4A795] bg-inherit w-full h-10 text-white`}
                  />
                  {touched.holdtill && (
                    <p className="text-red-700 mb-1 error_msg">{errors.holdtill}</p>
                  )}
                </div>
                <div className='cols-span-1'>
                  <div className='flex'>
                    <label className='block text-md font-medium text-[#E4A795]' >Customer Name</label>
                  </div>
                  <input
                    name="user_id"
                    label="user_id"
                    value={values.user_id ? login.role === 'BUSINESS' ? login.companyName : login.first_name + ' ' + (login.last_name ? login.last_name : ' ') :  ''}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                    placeholder='Enter Cusotmer Name...'
                    className={`p-1 border rounded-md border-1 border-[#E4A795] bg-inherit w-full h-10 text-white`}
                  />
                  {touched.user_id && (
                    <p className="text-red-700 mb-1 error_msg">{errors.user_id}</p>
                  )}
                </div>

                <div className='cols-span-1'>
                  <div className='flex'>
                    <label className='block text-md font-medium text-[#E4A795]' >Comments</label>
                  </div>
                  <textarea
                    name="comments"
                    label="comments"
                    value={values.comments}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setValues((prev) => ({
                        ...prev,
                        comments: e.target.value,
                      }))
                    }}
                    placeholder='Enter Comments...'
                    className={`p-1 border rounded-md border-1 border-[#E4A795] bg-inherit w-full h-10 text-white`}
                  />
                  {touched.comments && (
                    <p className="text-red-700 mb-1 error_msg">{errors.comments}</p>
                  )}
                </div>

              </div>
              <div className="mt-4 sm:mt-6 flex justify-end">
                <button className="border-2 border-[#E4A795] px-2 py-3 text-[#E4A795] sm:text-lg text-base text-center rounded-md transition ease-in-out delay-150 bg--[#E4A795] hover:-translate-y-1 hover:scale-110 hover:bg--[#E4A795] duration-300"
                  type="submit" 
                  disabled={isSubmitting}
                  >
                  Submit
                </button>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  return (
    <div className="px-4" style={{ backgroundColor: "#010519" }}>
      {searchValue == false && (
        <>
          <div className="container mx-auto my-auto">
            <div className="flex md:justify-between sm:items-center sm:flex-row flex-col px-5 py-10">
              <p
                className="text-teft md:text-6xl text-4xl sm:pt-2 md:pt-0 text-white"
                style={{ fontFamily: "Belleza" }}
              >
                Special Diamonds
              </p>
            </div>
            <div className="mx-5">
              <div className="flex gap-4 font-sans text-lg items-center">
                <p
                  className={classNames(
                    diamondShapeState == "round"
                      ? "text-[#E4A795] border-b-2 border-transparent border-[#E4A795]"
                      : "text-white",
                    "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                  )}
                  onClick={() => {
                    setDiamondShapePayload(
                      diamondShape.filter((e) => {
                        if (e.type == "round") return { name: e.name };
                      })
                    );
                    setDiamondShapeState("round");
                  }}
                >
                  Round
                </p>
                <p className="text-[#E4A795]">|</p>
                <p
                  className={classNames(
                    diamondShapeState == "fancy"
                      ? "text-[#E4A795] border-b-2 border-transparent border-[#E4A795]"
                      : "text-white",
                    "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                  )}
                  onClick={() => {
                    setDiamondShapePayload(
                      diamondShape.filter((e) => {
                        if (e.type == "fancy") return { name: e.name };
                      })
                    );
                    setDiamondShapeState("fancy");
                  }}
                >
                  Fancy
                </p>
              </div>
              <div class="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4 mt-4">
                {diamondShape.map((e) => {
                  return <>{renderShape(e)}</>;
                })}
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex flex-col md:flex-row lg:flex-row justify-start md:justify-between lg:justify-between items-start md:items-center lg:items-center gap-4 md:gap-0 lg:gap-0">
              <div className="w-full md:w-1/6 lg:w-1/6">
                <p className="text-white text-lg">Carat Range</p>
              </div>
              <div className="w-full md:w-5/6 lg:w-5/6">
                <div className="flex gap-4">
                  <input
                    type="number"
                    id="from"
                    name="from"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(value) => {
                      setFromCarat(value.target.valueAsNumber);
                    }}
                    className={`p-1 border rounded-md border-1 border-[#E4A795] bg-inherit w-full md:w-48 lg:w-48 h-10 text-white`}
                    placeholder="From"
                  />
                  <input
                    type="number"
                    id="to"
                    name="to"
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(value) => {
                      setToCarat(value.target.valueAsNumber);
                    }}
                    className={`p-1 border rounded-md border-1 border-[#E4A795] bg-inherit w-full md:w-48 lg:w-48 h-10 text-white`}
                    placeholder="To"
                  />
                  <button
                    className="rounded-md py-1 px-2 text-black bg-[#E4A795] font-normal h-10 text-xs md:text-lg lg:text-lg"
                    onClick={() => {
                      addCarat(fromCarat, toCarat);
                    }}
                  >
                    Add Carat
                  </button>
                </div>
                {addCaratPayload.length > 0 && (
                  <>
                    <div className="text-black text-sm grid grid-cols-4 md:grid-cols-8 lg:grid-cols-8 gap-4 mt-4">
                      {addCaratPayload.map((d, i) => {
                        return (
                          <>
                            <div className="p-2 flex justify-center items-center w-max rounded-md text-black bg-[#E4A795]">
                              <p className="text-md text-center">
                                {d.from} - {d.to}
                              </p>
                              <IoIosClose
                                className="w-8 h-8 text-white hover:text-black duration-500 cursor-pointer"
                                onClick={() => {
                                  removefromAddCarat(i);
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
                <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-4 mt-4">
                  {diamondCaratRange.map((e) => {
                    return <>{renderCaratRange(e)}</>;
                  })}
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex flex-col md:flex-row lg:flex-row justify-start md:justify-between lg:justify-between items-start md:items-center lg:items-center gap-4 md:gap-0 lg:gap-0">
              <div className="w-full md:w-1/6 lg:w-1/6">
                <p className="text-white text-lg">Color</p>
                <div className="flex gap-2">
                  <p
                    className={classNames(
                      diamondColorState == "group"
                        ? "text-[#E4A795] border-b-2 border-[#E4A795]"
                        : "text-white",
                      "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                    )}
                    onClick={() => {
                      setDiamondColorState("group");
                    }}
                  >
                    Color Group
                  </p>
                  <p className="text-[#E4A795]">|</p>
                  <p
                    className={classNames(
                      diamondColorState == "color"
                        ? "text-[#E4A795] border-b-2 border-[#E4A795]"
                        : "text-white",
                      "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                    )}
                    onClick={() => {
                      setDiamondColorState("color");
                    }}
                  >
                    Color
                  </p>
                </div>
              </div>
              <div className="w-full md:w-5/6 lg:w-5/6">
                <div class="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12 gap-4">
                  {diamondColorRange.map((e) => {
                    if (e.type == diamondColorState) {
                      return <>{renderColorRange(e)}</>;
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex flex-col md:flex-row lg:flex-row justify-start md:justify-between lg:justify-between items-start md:items-center lg:items-center gap-4 md:gap-0 lg:gap-0">
              <div className="w-full md:w-1/6 lg:w-1/6 flex flex-col">
                <p className="text-white text-lg">Clarity</p>
                <div className="flex gap-2">
                  <p
                    className={classNames(
                      diamondClarityState == "clarity_group"
                        ? "text-[#E4A795] border-b-2 border-[#E4A795]"
                        : "text-white",
                      "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                    )}
                    onClick={() => {
                      setDiamondClarityState("clarity_group");
                    }}
                  >
                    Clarity Group
                  </p>
                  <p className="text-[#E4A795]">|</p>
                  <p
                    className={classNames(
                      diamondClarityState == "clarity"
                        ? "text-[#E4A795] border-b-2 border-[#E4A795]"
                        : "text-white",
                      "text-md hover:text-[#E4A795] duration-500 cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#E4A795]"
                    )}
                    onClick={() => {
                      setDiamondClarityState("clarity");
                    }}
                  >
                    Clarity
                  </p>
                </div>
              </div>
              <div className="w-full md:w-5/6 lg:w-5/6">
                <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-4">
                  {diamondClarityRange.map((e) => {
                    if (e.type == diamondClarityState)
                      return <>{renderClarityRange(e)}</>;
                  })}
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex flex-col md:flex-row lg:flex-row justify-start md:justify-between lg:justify-between items-start md:items-center lg:items-center gap-4 md:gap-0 lg:gap-0">
              <div className="w-full md:w-1/6 lg:w-1/6 flex flex-col">
                <p className="text-white text-lg">Color Shade</p>
              </div>
              <div className="w-full md:w-5/6 lg:w-5/6">
                <div class="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12 gap-4">
                  {diamondColorShade.map((e) => {
                    return <>{renderColorShadeRange(e)}</>;
                  })}
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>

            <table className="text-white w-[97%] mx-5 hidden md:block lg:block">
              <tr className="">
                <td className="border-r border-[#E4A795] w-5/12 pt-4 text-lg">
                  <div className="flex">
                    <div className="w-5/12">
                      <p className="text-white text-lg">Round Make</p>
                    </div>
                    <div className="w-7/12 flex gap-2">
                      {diamondroundMake.map((e) => {
                        return <>{renderRoundMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="mt-4 flex">
                    <div className="w-5/12">
                      <p className="text-white text-lg">Fancy Make</p>
                    </div>
                    <div className="w-7/12 flex gap-2">
                      {diamondFancyMake.map((e) => {
                        return <>{renderFancyMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="mt-4 flex">
                    <div className="w-5/12">
                      <p className="text-white text-lg">Cut</p>
                    </div>
                    <div className="w-7/12 grid grid-cols-6 gap-4">
                      {DiamondCut.map((e) => {
                        return <>{renderCutMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="mt-4 flex">
                    <div className="w-5/12">
                      <p className="text-white text-lg">Polish</p>
                    </div>
                    <div className="w-7/12 grid grid-cols-6 gap-4">
                      {DiamondPolish.map((e) => {
                        return <>{renderPolishMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="mt-4 flex mb-5">
                    <div className="w-5/12">
                      <p className="text-white text-lg">Symmetry</p>
                    </div>
                    <div className="w-7/12 grid grid-cols-6 gap-4">
                      {DiamondSymmetry.map((e) => {
                        return <>{renderSymmetryMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
                <td
                  className="border-r border-[#E4A795] w-4/12 pt-4 text-lg pl-4"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="">
                    <div className="">
                      <p className="text-white text-lg">Fluorescence</p>
                    </div>
                    <div className="w-12/12 grid grid-cols-6 gap-4 mt-4">
                      {DiamondFluorescence.map((e) => {
                        return <>{renderFluorescenceMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
                <td
                  className="w-3/12 pt-4 text-lg pl-4 pb-4"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="">
                    <div className="">
                      <p className="text-white text-lg">Lab</p>
                    </div>
                    <div className="w-12/12 grid grid-cols-3 gap-4 mt-4">
                      {DiamondLab.map((e) => {
                        return <>{renderLabMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table className="text-white w-[90%] mx-5 block md:hidden lg:hidden">
              <tr className="border-b border-[#E4A795]">
                <td className="w-5/12 pt-4 text-lg">
                  <div className="w-5/12">
                    <p className="text-white text-lg">Round Make</p>
                  </div>
                  <div className="flex mt-1">
                    <div className="w-7/12 flex gap-2">
                      {diamondroundMake.map((e) => {
                        return <>{renderRoundMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="w-5/12 mt-6">
                    <p className="text-white text-lg">Fancy Make</p>
                  </div>
                  <div className="mt-1 flex">
                    <div className="w-7/12 flex gap-2">
                      {diamondFancyMake.map((e) => {
                        return <>{renderFancyMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="w-full mt-6">
                    <p className="text-white text-lg">Cut</p>
                  </div>
                  <div className="mt-1 flex">
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                      {DiamondCut.map((e) => {
                        return <>{renderCutMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="w-5/12 mt-6">
                    <p className="text-white text-lg">Polish</p>
                  </div>
                  <div className="mt-1 flex">
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                      {DiamondPolish.map((e) => {
                        return <>{renderPolishMake(e)}</>;
                      })}
                    </div>
                  </div>
                  <div className="w-5/12 mt-6">
                    <p className="text-white text-lg">Symmetry</p>
                  </div>
                  <div className="mt-1 flex mb-5">
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                      {DiamondSymmetry.map((e) => {
                        return <>{renderSymmetryMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-b border-[#E4A795]">
                <td
                  className="w-4/12 pt-4 text-lg"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="pb-5">
                    <div className="">
                      <p className="text-white text-lg">Fluorescence</p>
                    </div>
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4 mt-1">
                      {DiamondFluorescence.map((e) => {
                        return <>{renderFluorescenceMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td
                  className="w-3/12 pt-4 text-lg pb-5"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="">
                    <div className="">
                      <p className="text-white text-lg">Lab</p>
                    </div>
                    <div className="w-12/12 grid grid-cols-3 gap-4 mt-1">
                      {DiamondLab.map((e) => {
                        return <>{renderLabMake(e)}</>;
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex justify-between items-center hidden md:flex lg:flex">
              <div className="flex flex-col w-3/6">
                <div className="flex justify-between items-center">
                  <div className="w-2/6 flex flex-col">
                    <p className="text-white text-lg">H&A</p>
                  </div>
                  <div className="w-4/6">
                    <div class="grid grid-cols-5 gap-4">
                      {diamondColorShade.map((e) => {
                        return <>{renderHNA(e)}</>;
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div className="w-2/6 flex flex-col">
                    <p className="text-white text-lg">Availability status</p>
                  </div>
                  <div className="w-4/6">
                    <div class="grid grid-cols-5 gap-4">
                      {Diamonadavailability.map((e) => {
                        return <>{Availability(e)}</>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center w-2/6">
                <div className="w-full">
                  <div class="grid grid-cols-3 gap-4">
                    {DiamondStatus.map((e) => {
                      return <>{renderStatus(e)}</>;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5 flex flex-col block md:hidden lg:hidden">
              <div className="flex flex-col">
                <div className=" flex flex-col">
                  <p className="text-white text-lg">H&A</p>
                </div>
                <div className="flex justify-between items-center mt-1">
                  <div className="">
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                      {diamondColorShade.map((e) => {
                        return <>{renderHNA(e)}</>;
                      })}
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col mt-8">
                  <p className="text-white text-lg">Availability status</p>
                </div>
                <div className="flex justify-between items-center mt-1">
                  <div className="">
                    <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                      {Diamonadavailability.map((e) => {
                        return <>{Availability(e)}</>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center mt-8">
                <div className="w-full">
                  <div class="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4">
                    {DiamondStatus.map((e) => {
                      return <>{renderStatus(e)}</>;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mt-6 mx-5 flex justify-between items-center">
              <div className="flex flex-col w-3/6">
                <div className="flex flex-col md:flex-row lg:flex-row justify-start md:justify-between lg:justify-between items-start md:items-center lg:items-center">
                  <div className="w-full md:w-2/6 lg:w-2/6 flex flex-col">
                    <p className="text-white text-lg">Price & Disc %</p>
                  </div>
                  <div className="w-full md:w-4/6 lg:w-4/6 mt-4">
                    <div class="flex md:flex-row lg:flex-row flex-col gap-4">
                      <div className="flex flex-col">
                        <p className="text-sm text-white mb-2">Disc %</p>
                        <div className="flex gap-4">
                          <input
                            type="text"
                            id="from"
                            name="from"
                            onChange={(value) => {
                              setDiscFrom(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="From"
                          />
                          <input
                            type="text"
                            id="to"
                            name="to"
                            onChange={(value) => {
                              setDiscto(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="To"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-white mb-2">Price / Carat</p>
                        <div className="flex gap-4">
                          <input
                            type="text"
                            id="from"
                            name="from"
                            onChange={(value) => {
                              setPriceFrom(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="From"
                          />
                          <input
                            type="text"
                            id="to"
                            name="to"
                            onChange={(value) => {
                              setDiscto(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="To"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-white mb-2">Amount</p>
                        <div className="flex gap-4">
                          <input
                            type="text"
                            id="from"
                            name="from"
                            onChange={(value) => {
                              setAmountFrom(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="From"
                          />
                          <input
                            type="text"
                            id="to"
                            name="to"
                            onChange={(value) => {
                              setAmountto(value.target.valueAsNumber);
                            }}
                            className={`p-1 border rounded-md border-1 border-white bg-inherit w-36 md:w-48 lg:w-40 h-10`}
                            placeholder="To"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mx-5">
              <hr className="border-[#E4A795]"></hr>
            </div>
            <div className="mx-5 flex flex-col md:flex-row lg:flex-row justify-between items-start md:items-center lg:items-center gap-4 mt-4">
              <div class="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-4 gap-4">
                {DiamondTrade.map((e) => {
                  return <>{renderTrade(e)}</>;
                })}
              </div>
              <div className="w-full md:w-2/6 lg:w-2/6 mb-10">
                <div className="flex flex-col">
                  <div className="flex gap-4">
                    <input
                      type="text"
                      id="from"
                      name="from"
                      onChange={(value) => {
                        setPairNo(value.target.value);
                      }}
                      className={`p-1 border text-white rounded-md border-1 border-white bg-inherit w-full`}
                      placeholder="Pair No"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="sticky bottom-0 px-5 mt-10 pb-1">
              <div class="bg-[#010519] border-2 border-[#E4A795] p-4 rounded shadow-md flex gap-4 items-center">
                <button
                  type="button"
                  className="text-black text-sm px-3 py-1 bg-[#E4A795] rounded-md duration-500"
                  onClick={() => {
                    SearchValue(true);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {searchValue === true && (
        <>
          <div className="container mx-auto my-auto pb-1">
            <div className="flex md:justify-between sm:items-center sm:flex-row flex-col px-5 py-10">
              <p
                className="text-teft md:text-6xl text-4xl sm:pt-2 md:pt-0 text-white"
                style={{ fontFamily: "Belleza" }}
              >
                Special Diamonds {specialDiamond?.length > 0 && <>({count})</>}
              </p>
            </div>
            <div className="mx-5">
              {renderCompare()}
              {renderHold()}
              <Table
                progPage={page}
                totalCount={count}
                columns={catalogue_column({ onCheckBox })}
                data={specialDiamond}
                // onEditClose={cleanModalData}
                setSearchFilter={setSearchFilter}
              />
              <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-inherit px-1 py-3"
                aria-label="Pagination"
              >
                <div></div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative ml-3 bg-[#D6A093] items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#E4A789] hover:text-black duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm ml-2">
                    Page{" "}
                    <input
                      className="min-w-0 flex-1 rounded-md border border-[#D6A093] bg-inherit text-white px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                      type="number"
                      value={page + 1}
                      onChange={(e) => setPage(e.target.value - 1)}
                      max={totalPages}
                      min="1"
                    />
                    <span className="ml-2 text-white">
                      / {Math.ceil(totalPages)}
                    </span>
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 bg-[#D6A093] items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#E4A789] hover:text-black duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav>
            </div>
            <div class="sticky bottom-0 px-5 mt-2 ">
              <div class="bg-[#010519] border-2 border-[#E4A795] p-4 rounded shadow-md flex flex-col md:flex md:flex-row lg:flex lg:flex-row gap-4 md:items-center lg:items-center">
                <button
                  type="button"
                  className="text-black text-sm px-3 py-1 bg-[#E4A795] rounded-md duration-500"
                  onClick={() => {
                    SearchValue(false);
                  }}
                >
                  Modify Search
                </button>
                <button
                  type="button"
                  className="text-black text-sm px-3 py-1 bg-[#E4A795] rounded-md duration-500"
                  onClick={() => {
                    holdData();
                  }}
                >
                  Hold
                </button>
                <button
                  type="button"
                  className="text-black text-sm px-3 py-1 bg-[#E4A795] rounded-md duration-500"
                  onClick={() => {
                    compareStone()
                  }}
                >
                  Compare Stone
                </button>
                <Menu as="div" className="relative w-full md:w-[6rem] lg:w-[6rem]">
                <div>
                  <Menu.Button className="relative flex items-center text-sm w-full md:w-[6rem] lg:w-[6rem]">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <button
                      type="button"
                      className="text-black text-sm px-3 py-1 bg-[#E4A795] rounded-md duration-500 w-full"
                      // onClick={() => {
                      //   SearchValue(false);
                      // }}
                    >
                      Whatsapp
                    </button>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute -translate-y-full right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-300"
                            )}
                            onClick={() => {
                              copyToClipboard(item)
                            }}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              </div>
              
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default Special;
