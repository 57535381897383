import React, { useEffect } from "react";
import { useFormik } from "formik";
import { validationContact } from "../schema/index";
import app_api from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../auth/authContext";

const Customer = () => {
  const { setAuth } = useAuth();

  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("solitaireKey"))))
      setAuth(true);
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: validationContact,
    onSubmit: (values) => {
      // Handle form submission logic here
      app_api
        .post("user/contact", values)
        .then((res) => {
          if (res.data.statusCode) {
            toast.success(
              "Your message has been sent. An Executive will get in touch with you"
            );
            formik.resetForm();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  return (
    <>
      <div className="bg-[#010519]">
        <div className="text-white 4xl:p-30 3xl:p-24 2xl:p-18 xl:p-12 lg:p-6">
          <div className="bg-[#E4A795] lg:translate-y-3/4 xl:block  lg:block sm:hidden hidden lg:absolute lg:w-[35%] lg:p-5 rounded-lg">
            {/* <div className="lg:container lg:translate-y-2/4 2xl:w-full 4xl:ml-[65%] 3xl:ml-[50%] 2xl:ml-[25%] xl:w-[90%] lg:w-[50%] mb:w-[75%] md:w-full md:mb-2 bg-[#E4A795] lg:p-8 rounded shadow-lg mt-4 lg:mt-20 order-2 lg:order-1 max-h-fit h-full flex flex-col justify-between"> */}
            <p className="text-3xl font-bold leading-normal">Our Offices</p>
            <p className="font-source-sans-pro text-2xl font-semibold mt-5">
              Main Office
            </p>
            <p className="text-main font-source-sans-pro text-xl font-normal">
              36w W 47th St #208, New York, NY 10036, United States
            </p>
            <p className="font-source-sans-pro text-2xl font-semibold mt-5">
              Corporate Office
            </p>
            <p className="text-main font-source-sans-pro text-xl font-normal">
              36w W 47th St #208, New York, NY 10036, United States
            </p>
            {/* </div> */}
          </div>
          {/* <div className="flex relative lg:justify-end justify-center  order-2 lg:order-1  px-2 sm:pt-0 pt-4"> */}
          <div className="flex lg:border-2 lg:border-white lg:rounded-lg lg:border-solid  sm:pt-0 pt-4 lg:p-24 lg:ml-[15%] lg:justify-end justify-center  order-2 lg:order-1  px-2 sm:pt-0 pt-4">
            {/* <div className="flex lg:border-2 lg:border-white lg:rounded-lg lg:border-solid  lg:p-24 xl:pl-[35%] 2xl:pl-[40%]  lg:pl-[20%]"> */}
            <div className="w-full lg:w-2/3  py-6 sm:py-4 sm:px-4">
              <form onSubmit={formik.handleSubmit} className="sm:max-w-full max-w-full lg:max-w-[90%]">
              {/* max-w-fit sm:max-w-4xl */}
                <div className="mb-4 md:mt-3 font-sans text-white">
                  <h1 className="font-bold text-5xl">Get in Touch with us</h1>
                  <p className="mb-4 text-[#E4A795] text-2xl sm:pt-0 pt-4">
                    We will review your form and contact you very soon.
                  </p>
                  <label
                    htmlFor="name"
                    className="block text-base font-bold mb-2 font-sans text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`w-full p-2 border rounded bg-[#010519] ${
                      formik.touched.name && formik.errors.name
                        ? "border-red-500"
                        : ""
                    }`}
                    placeholder="Your Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-base font-bold mb-2 font-sans text-white"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className={`w-full p-2 border rounded  bg-[#010519]${
                      formik.touched.phone && formik.errors.phone
                        ? "border-red-500 bg-inherit"
                        : ""
                    }`}
                    placeholder="Your Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.phone}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-base font-bold mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`w-full p-2 border rounded  bg-[#010519]${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500 bg-inherit"
                        : ""
                    }`}
                    placeholder="Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-base font-bold mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className={`w-full p-2 border rounded bg-[#010519] ${
                      formik.touched.message && formik.errors.message
                        ? "border-red-500"
                        : ""
                    }`}
                    placeholder="Your Message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  ></textarea>
                  {formik.touched.message && formik.errors.message && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.message}
                    </div>
                  )}
                </div>
                <div className="w-full flex justify-center md:justify-end">
                  <button
                    type="submit"
                    className="bg-[#E4A795] text-xl w-full md:w-1/4 text-black font-medium p-2 rounded focus:outline-none focus:shadow-outline-blue transition ease-in-out delay-150 bg--[#E4A795] hover:-translate-y-1 hover:scale-110 hover:bg--[#E4A795] duration-300"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="xl:block text-white bg-[#010519] lg:block sm:hidden hidden lg:absolute">
          hello
        </div>
        <div className="flex relative lg:border-2 lg:border-white lg:rounded-lg lg:border-solid  lg:p-24 ml-[5%] lg:justify-end justify-center  order-2 lg:order-1  px-2 sm:pt-0 pt-4">
          <div className="flex ">no</div>
        </div> */}
          <div className="flex flex-col  mx-auto  my-auto p-8">
            {/* lg:flex-row gap-y-3 md:h-96 md:gap-y-16 justify-between  rounded shadow-lg mb-4 lg:mb-0 order-2 lg:order-3 */}
            <div className="flex flex-col mb-3">
              <label>New York</label>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.2175257611825!2d-73.98296672423785!3d40.757240171386876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25953d85109c3%3A0x158e83d3b1b7a258!2sSolitaire%20Planet!5e0!3m2!1sen!2sin!4v1703599487955!5m2!1sen!2sin"
                className="md:h-screen w-full border-solid border-0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
