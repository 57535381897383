import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import app_api from "../config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KycSchema, KycSchemaCreditCard } from "../schema";
import Select from "react-select"

const initialValues = {
  companyName: "",
  tradeName: "",
  businessTel: "",
  taxId: "",
  faxNo: "",
  companyEmail: "",
  website: "",
  instaGram: "",
  facebook: "",
  sjo: false,
  ijo: false,
  rjo: false,
  cbg: false,
  prime: false,
  lig: false,
  big: false,
  howDid: "TRADE SHOW",
  fromWhere: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  city: "",
  state: "",
  shipaddress1: "",
  shipaddress2: "",
  shipZipCode: "",
  shipCity: "",
  shipState: "",
  noOfYear: "",
  requestCatalogue: false,
  sameAs: false,
  mainFirstName: "",
  mainLastName: "",
  mainPhoneNo: "",
  mainEmail: "",
  isAccount: false,
  AccountFirstName: "",
  AccountLastName: "",
  AccountPhoneNo: "",
  AccountEmail: "",
  isMarketing: false,
  MarketingFirstName: "",
  MarketingLastName: "",
  MarketingPhoneNo: "",
  MarketingEmail: "",
  creditAmount: "",
  JBTNumber: "",
  JBTPhoneNumber: "",
  resaleCertificate: "",
  applicantName: "",
  date: "",
  gurantee: false,
  refCompanyName1: "",
  refCompanyName2: "",
  refCompanyName3: "",
  refPhoneNumber1: "",
  refPhoneNumber2: "",
  refPhoneNumber3: "",
  pointOfContact1: "",
  pointOfContact2: "",
  pointOfContact3: "",
  refEmail1: "",
  refEmail2: "",
  refEmail3: "",
}


const about_us = [
  {
    id: 'TRADE SHOW',
    name: 'TRADE SHOW',
    value: 'TRADE SHOW',
  },
  {
    id: 'WEBSITE',
    name: 'WEBSITE',
    value: 'WEBSITE',
  },
  {
    id: 'SALES REP',
    name: 'SALES REP',
    value: "SALES REP"

  },
  {
    id: 'REFERRAL',
    name: 'REFERRAL',
    value: 'REFERRAL'
  },
  {
    id: 'OTHER',
    name: 'OTHER',
    value: 'OTHER'
  },
]


const Kyc = () => {
  const [todayDate, setTodayDate] = useState("")
  const [selectedValue, setSelectedValue] = useState("");
  const [rciValue, setRCIValue] = useState("")
  const [amlValue, setAMLValue] = useState("Yes")
  const [payload, setPayload] = useState([])
  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [countries2, setCountries2] = useState();
  const [states2, setStates2] = useState();
  const [changeSchema, setChangeSchema] = useState(false);

  const handleRCIRadioChange = (e) => {
    setRCIValue(e.target.value)
  }

  useEffect(() => {
    if(rciValue === "Credit Card"){
      setChangeSchema(true)
    }
  },[rciValue])

  const colourStyles = {
    control: (styles, state) => ({ ...styles, backgroundColor: state.isDisabled ? '#78716C' : '#010519', borderColor: 'white',height: '45px', }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#010519',
        color: isSelected ? 'white' : '#E4A695',
        cursor: 'default',
        zIndex: 1000,
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white', // Set the text color to white for the selected value
      fontSize: '15px'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#334154', // Set the color of the placeholder text
      fontSize: '15px'
    }),
  };

  useEffect(() => {
    getAllCountries()
  },[])

  const getAllCountries = () => {
    app_api.get('auth/country')
    .then((res) => {
      setCountries(res.data.data)
      setCountries2(res.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState2 = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates2(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleAMLRadioChange = (e) => {
    if (e.target.value == "Yes") {
      setAMLValue(true)
    } else {
      setAMLValue(false)
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setTodayDate(getTodayDate());
  }, []);


  return (
    <div className="bg-[#010519]">
      <div classNameName="flex flex-col lg:flex-row overflow-y">
        <div className="flex  justify-center px-8 py-8 box-border">
          <div className="max-w-7xl"></div>
          <Formik
            initialValues={initialValues}
            validationSchema={changeSchema ? KycSchemaCreditCard : KycSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm }) => {
              if (values.sameAs == true) {
                values = { ...values, sameAs: true };
                delete values.shipaddress1;
                delete values.shipaddress2;
                delete values.shipCity;
                delete values.shipZipCode;
                delete values.shipState;
                delete values.country2
              }
              if (values.isAccount == true) {
                values = { ...values, isAccount: true };
                delete values.AccountFirstName;
                delete values.AccountLastName;
                delete values.AccountEmail;
                delete values.AccountPhoneNo;
              }
              if (values.isMarketing == true) {
                values = { ...values, isMarketing: true };
                delete values.MarketingFirstName;
                delete values.MarketingLastName;
                delete values.MarketingEmail;
                delete values.MarketingPhoneNo;
              }
              console.log(values)
              app_api
                .post('kyc', {
                  ...values, userId: JSON.parse(localStorage.getItem('solitaireKey')).id,
                  RCI: rciValue, typeOfBusiness: selectedValue, AML: amlValue, date: todayDate, noOfYear: parseInt(values.noOfYear),
                  creditAmount: parseFloat(values.creditAmount)
                }).then((res) => {
                  toast.success('Successfully KYC Marked')
                  setTimeout(function () {
                    navigate("/");
                  }, 2000);
                  localStorage.removeItem('solitaireKey');
                }).catch((err) => {
                  toast.error('Failed to KYC Marked')
                })
            }}

          >
            {({
              handleBlur,
              Formik,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setValues,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
            }) => (
              <div>
                <form id="mainForm" onSubmit={handleSubmit}>
                  <div className="">
                    <div className="mb-6">
                      <div className="flex flex-wrap mb-4">
                        <div className="max-w-full text-[#E4A795]">
                          <b>
                            The more information you can provide us, the
                            faster your account will be processed.
                          </b>
                        </div>
                      </div>
                      <div className="flex md:flex-row flex-col gap-4 ">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label for="companyName" className="text-[#E4A795]">
                            Company Name
                            <span className="text-white ml-1">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyName"
                            maxlength="40"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="companyName"
                            placeholder="Enter Company Name"
                            values={values.companyName}
                            onBlur={handleBlur}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                companyName: value.target.value
                              }))
                            }}
                          />
                          {touched.companyName && (
                            <p className="text-red-700 error_msg">
                              {errors.companyName}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label for="tradeName" className="text-[#E4A795]">
                            Trade Name <span className="text-sm"> (if different from company name)</span>
                          </label>
                          <Field
                            type="text"
                            name="tradeName"
                            maxlength="50"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="tradeName"
                            placeholder="Enter Trade Name"
                            values={values.tradeName}
                            onBlur={handleBlur}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                tradeName: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label for="tradeName" className="text-[#E4A795]">
                            Tax ID
                          </label>
                          <Field
                            type="text"
                            name="tax"
                            maxlength="50"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="tax"
                            placeholder="Enter Tax ID"
                            values={values.taxId}
                            onBlur={handleBlur}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                taxId: value.target.value
                              }))
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="businessTel">
                            Business Telephone{" "}
                            <span className="text-white ml-1">*</span>
                          </label>
                          <Field
                            type="tel"
                            name="businessTel"
                            maxlength="10"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="businessTel"
                            placeholder="Enter Telephone Number"
                            values={values.businessTel}
                            onBlur={handleBlur}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                businessTel: value.target.value
                              }))
                            }}
                          />
                          {touched.businessTel && (
                            <p className="text-red-700 error_msg">
                              {errors.businessTel}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="faxNo">FAX</label>
                          <Field
                            type="tel"
                            name="faxNo"
                            maxlength="10"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="faxNo"
                            placeholder="Enter FAX Number"
                            values={values.faxNo}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                faxNo: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="companyEmail">
                            Company Email Address <span className="text-white ml-1">*</span>
                          </label>
                          <Field
                            type="email"
                            maxlength="50"
                            name="companyEmail"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="companyEmail"
                            placeholder="Enter Email Address"
                            values={values.companyEmail}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.companyEmail && (
                            <p className="text-red-700 error_msg">
                              {errors.companyEmail}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="website">Website</label>
                          <Field
                            type="text"
                            name="website"
                            maxlength="50"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="website"
                            placeholder="Enter website URL"

                            values={values.website}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                website: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="facebook">Facebook</label>
                          <Field
                            type="text"
                            name="facebook"
                            maxlength="256"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="facebook"
                            placeholder="Enter Facebook handle"

                            values={values.facebook}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                facebook: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full text-[#E4A795]">
                          <label for="instagram">Instagram</label>
                          <Field
                            type="text"
                            name="instagram"
                            maxlength="256"
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="instagram"
                            placeholder="Enter Instagram handle"

                            values={values.instaGram}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                instaGram: value.target.value
                              }))
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row my-3 gap-4 ">
                        <div className="flex flex-col flex-wrap py-2.5 my-3 md:w-1/2 w-full text-[#9a9fa8]">
                          <label className="text-[#E4A795]">
                            Buying group : Select buying group of which you
                            are a member
                          </label>
                          <div className="mt-2 flex flex-wrap">
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              //
                              for="sjo"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="sjo"
                                name="sjo"
                                values={values.sjo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    sjo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">SJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="ijo"

                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="ijo"
                                name="ijo"
                                values={values.ijo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    ijo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">IJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="rjo"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="rjo"
                                name="rjo"
                                values={values.rjo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    rjo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">RJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="cbg"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="cbg"
                                name="cbg"
                                values={values.cbg}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    cbg: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">CBG</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="prime"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="prime"
                                name="prime"
                                values={values.prime}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    prime: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">
                                PRIME
                              </span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="lig"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="lig"
                                name="lig"
                                values={values.lig}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    lig: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">LIG</span>
                            </label>
                            <label
                              className="text-center mr-3 mb-3"
                              for="big"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="big"
                                name="big"
                                values={values.big}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    big: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 text-[#E4A795]">big</span>
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col py-2.5 my-3 h-11 md:w-1/2 w-full text-[#E4A795]">
                          <label for="howDid">
                            How did you hear about us?
                          </label>
                          <select
                            className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            name="howDid"
                            id="howDid"
                            value={values.howDid || ""}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                howDid: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled>Select an option</option>
                            {about_us.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {values.howDid != 'TRADE SHOW' && values.howDid != 'WEBSITE' && (
                        <div className="flex flex-col md:flex-row gap-4">
                          <div className="flex flex-col py-2.5 my-3 h-11 sm:w-1/2 md:w-full w-full text-[#E4A795]"></div>
                          <div className="flex flex-col h-11 sm:w-1/2 md:w-full w-full text-[#E4A795]">
                            <input
                              type="text"
                              placeholder={`Please Specify`}
                              className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                              value={values.fromWhere || ""}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  fromWhere: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-12">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm pb-4">
                        <div className="p-3 mb-0 block text-xl font-medium text-[#E4A795]">
                          <b>Address Information</b>
                        </div>
                        <div className="flex-1 min-h-1 p-5">
                          <div className="md:mb-0 mb-4 px-1 block text-xl font-medium text-[#E4A795]">
                            Bill To
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full block text-[#E4A795]">
                              <label for="addressLine1">
                                Address Line 1{" "}
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="addressLine1"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="addressLine1"
                                placeholder="Enter Address Line 1"
                                value={values.addressLine1}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    addressLine1: option.target.value,
                                  });
                                }}
                                onBlur={() => setFieldTouched('addressLine1', true)}
                              />
                              {touched.addressLine1 && (
                                <p className="text-red-700 error_msg">
                                  {errors.addressLine1}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                              <label for="address2">
                                Address Line 2{" "}
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="address2"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="address2"
                                placeholder="Enter Address Line 2"
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    addressLine2: option.target.value,
                                  });
                                }}
                                value={values.addressLine2}
                              />
                              {/* {touched.addressLine2  && (
                                <p className="text-red-700 error_msg">
                                  {errors.addressLine2}
                                </p>
                              )} */}
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                              <label for="zipCode">
                                Zip <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="number"
                                name="zipCode"
                                maxlength="5"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="zipCode"
                                placeholder="Enter Zip"

                                min="0"

                                value={values.zipCode}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    zipCode: option.target.value,
                                  });
                                }}
                              />
                              {touched.zipCode && (
                                <p className="text-red-700 error_msg">
                                  {errors.zipCode}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                    <div>
                      <div className="flex">
                        <label className="block font-medium text-[#E4A795]">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country1"
                      id="country1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country1: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country1 && (
                        <p className="text-red-700 error_msg">
                          {errors.country1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                    <div>
                      <div className="flex">
                        <label className="block font-medium text-[#E4A795]">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state"
                      id="state"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={states?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state && (
                        <p className="text-red-700 error_msg">
                          {errors.state}
                        </p>
                      )}
                    </div>
                  </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                              <label for="city">
                                City{" "}
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="city"
                                maxlength="20"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="city"
                                placeholder="Enter City"


                                // onChange={(e) =>
                                //   handleBillToChange("city", e.target.value)
                                // }
                                value={values.city}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    city: option.target.value,
                                  });
                                }}
                              />
                              {touched.city && (
                                <p className="text-red-700 error_msg">
                                  {errors.city}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="my-2 col-md-12 px-1 pt-4 block text-xl font-medium text-[#E4A795]">
                            Ship To <small>(PO Boxes not allowed)</small>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="my-2 col-md-12">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                                for="sameAsBillTo"
                              >
                                <input
                                  type="checkbox"
                                  className="absolute left-0 z-[-1] w-6 h-6 top-0"
                                  value={values.sameAs}
                                  id='sameAs'
                                  name='sameAs'
                                  checked={values.sameAs}
                                  onChange={(same) => {
                                    setValues((sameval) => ({
                                      ...sameval,
                                      sameAs: same.target.checked
                                    }))
                                  }}
                                  disabled={values.addressLine1 != null && values.addressLine2 != null && values.zipCode != null && values.city != "" && values.state != null && values.country1 != null ? false : true}
                                />
                                <span className="pl-3 font-normal text-[#E4A795] cursor-pointer select-none">
                                  Same as Bill To
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full text-[#E4A795] max-w-full">
                              <label for="shipToaddress1">
                                Address Line 1
                              </label>
                              <Field
                                type="text"
                                name="shipToaddress1"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="shipToaddress1"
                                placeholder="Enter Address Line 1"
                                value={values.sameAs ? values.addressLine1 : values.shipaddress1}
                                onChange={(e) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    shipaddress1: e.target.value
                                  }))
                                }}
                                disabled={values.sameAs ? true : false}
                              />
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795] max-w-full">
                              <label for="shipToaddress2">
                                Address Line 2
                              </label>
                              <Field
                                type="text"
                                name="shipToaddress2"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="shipToaddress2"
                                placeholder="Enter Address Line 2"
                                value={values.sameAs ? values.addressLine2 : values.shipaddress2}
                                onChange={(e) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    shipaddress2: e.target.value
                                  }))
                                }}
                                disabled={values.sameAs ? true : false}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795] col-md-4">
                              <label for="shipTozip">Zip </label>
                              <Field
                                type="number"
                                name="shipTozip"
                                maxlength="6"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="shipTozip"
                                placeholder="Enter Zip"

                                min="0"
                                value={values.sameAs ? values.zipCode : values.shipZipCode}
                                onChange={(e) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    shipZipCode: e.target.value
                                  }))
                                }}
                                disabled={values.sameAs ? true : false}
                              />
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                    <div>
                      <div className="flex">
                        <label className="block font-medium text-[#E4A795]">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country2"
                      id="country2"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.sameAs ?
                        {
                          value: countries?.find(
                            (e) => e.name == values.country1
                          )?.isoCode,
                          label: countries?.find(
                            (e) => e.name == values.country1
                          )?.name,
                        }
                        :
                        values.country2
                          ? countries2?.map((l) => ({
                            value: countries2?.find(
                              (e) => e.name == values.country2
                            )?.isoCode,
                            label: countries2?.find(
                              (e) => e.name == values.country2
                            )?.name,
                          }))
                          : null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country2: option.label,
                        }));
                        getState2(option.value)
                      }}
                      isDisabled={values.sameAs ? true : false}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country2 && (
                        <p className="text-red-700 error_msg">
                          {errors.country2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795]">
                    <div>
                      <div className="flex">
                        <label className="block font-medium text-[#E4A795]">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state2"
                      id="state2"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      isDisabled={values.sameAs ? true : false}
                      options={states2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.sameAs ?
                        {
                          value: states?.find(
                            (e) => e.name == values.state
                          )?.isoCode,
                          label: states?.find(
                            (e) => e.name == values.state
                          )?.name,
                        }
                        :
                        values.state2
                          ? states2?.map((l) => ({
                            value: states2?.find(
                              (e) => e.name == values.state2
                            )?.isoCode,
                            label: states2?.find(
                              (e) => e.name == values.state2
                            )?.name,
                          }))
                          : null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state2: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state2 && (
                        <p className="text-red-700 error_msg">
                          {errors.state2}
                        </p>
                      )}
                    </div>
                  </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full text-[#E4A795] col-md-4">
                              <label for="shipTocity">City</label>
                              <Field
                                type="text"
                                name="shipTocity"
                                maxlength="20"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="shipTocity"
                                placeholder="Enter City"
                                value={values.sameAs ? values.city : values.shipCity}
                                onChange={(e) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    shipCity: e.target.value
                                  }))
                                }}
                                disabled={values.sameAs ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-wrap">
                        <div className="col-md-3 py-2">
                          <label
                            className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                            for="requestCatalog"
                          >
                            <input
                              type="checkbox"
                              className="absolute left-0 z-[-1] w-6 h-6  top-0"
                              id="requestCatalog"
                              name="requestCatalog"
                              value={values.requestCatalogue}
                              checked={values.requestCatalogue}
                              onChange={(same) => {
                                setValues((sameval) => ({
                                  ...sameval,
                                  requestCatalogue: same.target.checked
                                }))
                              }}
                            />
                            <span
                              className="pl-3 text-red-300 cursor-pointer select-none"
                            >
                              Request Catalog
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0 text-[#E4A795]">
                          <b>Contact Information</b>
                        </div>
                        <div className="p-3 mb-0 text-[#E4A795]">
                          Please note, the Main Contact’s email will be the
                          primary email used to create a web login to our
                          website, as well as where order tracking information
                          will be sent. You will receive a confirmation at
                          that email once your account is approved.
                        </div>
                        <div className="flex-1 min-h-1 p-5">
                          <div className="flex flex-col lg:flex-row mb-6 gap-4 ">
                            <div className=" text-[#E4A795] mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none md:w-1/5 w-full max-w-full pt-4">
                              Main contact
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mcfirstname">
                                First Name
                                <span className="text-white ml-1">*</span>
                              </label>

                              <Field
                                type="text"
                                name="mcfirstname"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mcfirstname"
                                placeholder="First Name"


                                value={values.mainFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainFirstName: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainFirstName && errors.mainFirstName && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainFirstName}
                                </p>
                              )}
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mclastname">
                                Last Name
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="mclastname"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mclastname"
                                placeholder="Last Name"


                                value={values.mainLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainLastName: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainLastName && errors.mainLastName && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainLastName}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mcphonenumber">
                                Phone number
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="tel"
                                name="mcphonenumber"
                                maxlength="10"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mcphonenumber"
                                placeholder="Phone Number"

                                value={values.mainPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainPhoneNo: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainPhoneNo && errors.mainPhoneNo && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainPhoneNo}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mcemail">
                                Email
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="mcemail"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mcemail"
                                placeholder="Enter Email"

                                value={values.mainEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainEmail: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainEmail && errors.mainEmail && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainEmail}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row mb-6 gap-4">
                            <div className="mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none md:w-1/5 w-full max-w-full pt-4">
                              <div classNameName="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/5 max-w-full text-[#E4A795]">
                                <p className="text-[#E4A795]">
                                  Accounts payable
                                </p>
                              </div>
                              <div className="flex flex-col mb-4 md:w-full w-1/5 max-w-full">
                                <label
                                  className="custom-control custom-checkbox"
                                  for="mainContactCopyAP"
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-Field"
                                    id="mainContactCopyAP"
                                    name="mainContactCopyAP"
                                    value={values.isAccount}
                                    checked={values.isAccount}
                                    onChange={(eve) => {
                                      setValues((val) => ({
                                        ...val,
                                        isAccount: eve.target.checked
                                      }))
                                    }}
                                    disabled={values.mainFirstName && values.mainLastName && values.mainEmail && values.mainPhoneNo ? false : true}
                                  />
                                  <span className="pl-3 cursor-pointer select-none text-[#E4A795]">
                                    Same as Main Contact
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="apfirstname">First Name</label>
                              <Field
                                type="text"
                                name="apfirstname"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="apfirstname"
                                placeholder="First Name"
                                value={values.isAccount === true ? values.mainFirstName : values.AccountFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountFirstName: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="aplastname">Last Name</label>
                              <Field
                                type="text"
                                name="aplastname"
                                maxlength="40"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="aplastname"
                                placeholder="Last Name"
                                value={values.isAccount === true ? values.mainLastName : values.AccountLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountLastName: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="apphonenumber">Phone number</label>
                              <Field
                                type="tel"
                                name="apphonenumber"
                                maxlength="10"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"

                                id="apphonenumber"
                                placeholder="Phone Number"
                                // value={apData.phoneNumber}
                                value={values.isAccount === true ? values.mainPhoneNo : values.AccountPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountPhoneNo: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="apemail">Email</label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="apemail"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="apemail"
                                placeholder="Enter Email"
                                // value={apData.email}
                                value={values.isAccount === true ? values.mainEmail : values.AccountEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountEmail: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row mb-6 gap-4">
                            <div className="mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none w-1/5 max-w-full pt-4">
                              <div classNameName="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/5 max-w-full text-[#E4A795]">
                                {/* <div className="w-100 d-block mb-1"> */}
                                <p className="text-[#E4A795]">
                                  Marketing manager
                                </p>
                              </div>
                              <label
                                className="custom-control custom-checkbox"
                                for="mainContactCopyMM"
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-Field"
                                  id="mainContactCopyMM"
                                  name="mainContactCopyMM"
                                  value={values.isMarketing}
                                  checked={values.isMarketing}
                                  onChange={(eve) => {
                                    setValues((val) => ({
                                      ...val,
                                      isMarketing: eve.target.checked
                                    }))
                                  }}
                                  disabled={values.mainFirstName && values.mainLastName && values.mainEmail && values.mainPhoneNo ? false : true}
                                />
                                <span className="pl-3 cursor-pointer select-none text-[#E4A795]">
                                  Same as Main Contact
                                </span>
                              </label>
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mmpfirstname">First Name</label>
                              <Field
                                type="text"
                                maxlength="40"
                                name="mmpfirstname"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="mmpfirstname"
                                placeholder="First Name"
                                value={values.isMarketing === true ? values.mainFirstName : values.MarketingFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingFirstName: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mmplastname">Last Name</label>
                              <Field
                                type="text"
                                maxlength="40"
                                name="mmplastname"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="mmplastname"
                                placeholder="Last Name"
                                value={values.isMarketing === true ? values.mainLastName : values.MarketingLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingLastName: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mmphonenumber">Phone number</label>
                              <Field
                                type="tel"
                                maxlength="10"
                                name="mmphonenumber"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"

                                id="mmphonenumber"
                                placeholder="Phone Number"
                                value={values.isMarketing === true ? values.mainPhoneNo : values.MarketingPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingPhoneNo: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full mdw-full lg:w-1/5 max-w-full text-[#E4A795]">
                              <label for="mmemail">Email</label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="mmemail"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-stone-500"
                                id="mmemail"
                                placeholder="Enter Email"
                                value={values.isMarketing === true ? values.mainEmail : values.MarketingEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingEmail: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0">
                          <b className="text-[#E4A795]">Type of Business</b>
                        </div>
                        <div className="flex-1 min-h-1 p-5">
                          <div className="flex flex-wrap -mx-4  gap-4 flex-1 min-h-1 p-5">
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="wholesaler"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="wholesaler"
                                  value="wholesaler"
                                  // checked={true}
                                  checked={selectedValue === "wholesaler"} // Check if the value matches the selectedValue
                                  onChange={handleRadioChange} // Call the event handler on change
                                />
                                <span className="pl-3 cursor-pointer select-none text-[#E4A795]">
                                  Wholesaler
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="chainstore"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="chainstore"
                                  value="chain_store"
                                  checked={selectedValue === "chain_store"}
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Chain Store
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="manufacturer"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="manufacturer"
                                  value="manufacturer"
                                  checked={selectedValue === "manufacturer"}
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Manufacturer
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="departmentstore"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="departmentstore"
                                  value="departmentstore"
                                  checked={
                                    selectedValue === "departmentstore"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Department Store
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="internetcompany"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="internetcompany"
                                  value="internetcompany"
                                  checked={
                                    selectedValue === "internetcompany"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Internet Company
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-4 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="independentjeweler"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="independentjeweler"
                                  value="independentjeweler"
                                  checked={
                                    selectedValue === "independentjeweler"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Independent Jeweler
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="mb-4 flex-none w-full md:w-1/4 max-w-full mt-3">
                            <div className="mb-4 col-md-6 text-[#E4A795]">
                              <label for="numberofyears">
                                Number of years in business
                              </label>
                              <Field
                                type="number"
                                name="numberofyears"

                                className="bg-[#010519] text-white block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                                id="numberofyears"
                                placeholder="Enter Number of years"
                                maxlength="3"
                                min="0"
                                values={values.noOfYear}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    noOfYear: value.target.value
                                  }))
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 mb-2"></div>
                    <div className="mb-6">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0 text-[#E4A795]">
                          <b>
                            How would you like to do business with RCI?{" "}
                            <span className="text-white ml-1">*</span>
                          </b>
                        </div>
                        <div className="flex-1 min-h-1 p-5">
                          <div className="flex flex-wrap -mx-4  gap-4 flex-1 min-h-1 p-5">
                            <div className="col-sm-3 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="creditTerms"
                              >
                                <Field
                                  type="radio"
                                  id="creditTerms"
                                  name="rci_radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  value="Credit Terms"
                                  checked={rciValue === "Credit Terms"}
                                  onChange={handleRCIRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none text-[#E4A795]">
                                  Credit Terms
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-3 py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="creditCard"
                              >
                                <Field
                                  type="radio"
                                  id="creditCard"
                                  name="rci_radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  value="Credit Card"
                                  checked={rciValue === "Credit Card"}
                                  onChange={handleRCIRadioChange}
                                />
                                <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                  Credit Card
                                </span>
                              </label>
                            </div>
                          </div>

                          {/* <div
                            className="flex flex-col mb-6 gap-4 jbt-row credit_card"
                            // style="display: none;"
                          ></div> */}

                          {rciValue != 'Credit Card' && (<>
                            <div className="flex flex-col mb-6 gap-4 gap-4">
                              <div className="flex flex-col py-2.5 my-3 h-11 md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                <label for="jbtcreditamount">
                                  Credit amount{" "}
                                </label>
                                <Field
                                  type="number"
                                  name="jbtcreditamount"
                                  className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                  id="jbtcreditamount"
                                  placeholder="Enter requested credit amount"
                                  text="number"
                                  maxlength="8"
                                  min="0"
                                  // checked={selectedValue === "credit_amount"}
                                  // onChange={handleRadioChange}
                                  values={values.creditAmount}
                                  onChange={(value) => {
                                    setValues((val) => ({
                                      ...val,
                                      creditAmount: value.target.value,
                                    }));
                                  }}
                                />
                                {/* {touched.jbtcreditamount &&
                                errors.jbtcreditamount && (
                                  <p className="text-red-700 error_msg">
                                    {errors.jbtcreditamount}
                                  </p>
                                )} */}
                              </div>

                              <div className="flex flex-col md:flex-row gap-4 my-6">
                                <div className="flex flex-col py-2.5  my-3 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                  <label for="JBTNumber">JBT Number</label>
                                  <Field
                                    type="number"
                                    name="JBTNumber"
                                    maxlength="10"

                                    className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                    id="JBTNumber"
                                    placeholder="Enter JBT Number"
                                    min="0"
                                    values={values.JBTNumber}

                                    onChange={(value) => {
                                      setValues((val) => ({
                                        ...val,
                                        JBTNumber: value.target.value
                                      }))
                                    }}
                                  />
                                </div>

                                <div className="flex flex-col py-2.5  my-3 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                  <label for="jbtphonenumber">
                                    JBT Phone number
                                  </label>
                                  <Field
                                    type="tel"
                                    name="jbtphonenumber"
                                    maxlength="10"
                                    className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"

                                    id="jbtphonenumber"
                                    placeholder="Phone Number listed in JBT"
                                    values={values.JBTPhoneNumber}
                                    onChange={(value) => {
                                      setValues((val) => ({
                                        ...val,
                                        JBTPhoneNumber: value.target.value,
                                      }));
                                    }}
                                  />
                                  {/* {touched.jbtphonenumber &&
                                  errors.jbtphonenumber && (
                                    <p className="text-red-700 error_msg">
                                      {errors.jbtphonenumber}
                                    </p>
                                  )} */}
                                </div>
                              </div>

                              <div className="mb-4 w-full col-md-8">
                                <div className="p-3 mb-0 px-2 text-lg text-[#E4A795]">
                                  Trade References (We highly recommend adding
                                  at least 3 references)
                                </div>
                                <div className="flex-1 min-h-1 px-2">
                                  <p className="pt-4 text-lg text-[#E4A795]">First Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />

                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#9a9fa8] col-md-4 mb-2">
                                      <label
                                        className="text-[#E4A795]"
                                        for="refCompanyName1"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName1"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName1"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName1: value.target.value
                                          }))
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      {touched.refCompanyName1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName1}
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="pointOfContact1">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact1"
                                        maxlength="50"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact1"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact1}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refEmail1">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail1"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail1"
                                        placeholder="Enter company email"
                                        values={values.refEmail1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail1}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refPhoneNumber1">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber1"
                                        maxlength="10"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber1"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber1}
                                        </p>)}
                                    </div>
                                  </div>

                                  <p className="pt-4 text-lg text-[#E4A795]">Second Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />
                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#9a9fa8] col-md-4 mb-2">
                                      <label
                                        className="text-[#E4A795]"
                                        for="refCompanyName2"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName2"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName2"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName2: value.target.value
                                          }))
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      {touched.refCompanyName2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName2}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="pointOfContact3">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact2"
                                        maxlength="50"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact2"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact2}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refEmail2">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail2"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail2"
                                        placeholder="Enter company email"
                                        values={values.refEmail2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail2}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refPhoneNumber2">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber2"
                                        maxlength="10"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber2"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber2}
                                        </p>)}
                                    </div>
                                  </div>
                                  <p className="pt-4 text-lg text-[#E4A795]">Third Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />

                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#9a9fa8] col-md-4 mb-2">
                                      <label
                                        className="text-[#E4A795]"
                                        for="refCompanyName3"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName3"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName3"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refCompanyName3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName3}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="pointOfContact3">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact3"
                                        maxlength="50"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact3"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact3}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refEmail3">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail3"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail3"
                                        placeholder="Enter company email"
                                        values={values.refEmail3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail3}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full text-[#E4A795] col-md-4 mb-2">
                                      <label for="refPhoneNumber3">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber3"
                                        maxlength="10"
                                        className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber3"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber3}
                                        </p>)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>)}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 align-items-center mb-2">
                      <div className="flex flex-col py-2.5 md:my-6 my-3 h-11 md:w-1/3 w-full text-[#9a9fa8] max-w-1/3">
                        <label
                          for="resalecertificate"
                          className="text-[#E4A795] text-base mb-0 "
                        >
                          Resale certificate #/Tax Exempt #{" "}
                          <span className="text-white ml-1">*</span>
                        </label>
                      </div>
                      <div className="px-4 mb-4  md:my-6 my-3 flex-none md:w-2/3 w-full max-w-full mb-4">
                        <Field
                          type="text"
                          name="resaleCertificate"
                          // className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                          className="bg-[#010519] text-white block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                          id="resaleCertificate"
                          placeholder="Enter"
                          maxlength="50"
                          values={values.resaleCertificate}
                          onChange={(value) => {
                            setValues((val) => ({
                              ...val,
                              resaleCertificate: value.target.value
                            }))
                          }}
                        />
                        {touched.resaleCertificate && (
                          <p className="text-red-700 error_msg">
                            {errors.resaleCertificate}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row mb-6 gap-4">
                      <div className="mb-4 w-full max-w-full text-[#E4A795] my-2">
                        <p>
                          Is your company AML (Anti Money Laundering)
                          Complaint pursuant to the USA Patriot Act
                        </p>
                        <div>
                          <label
                            // className="custom-control custom-radio d-flex mb-3 w-100"
                            className="flex flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2"
                            for="amlprogramyes"
                          >
                            <Field
                              type="radio"
                              id="amlprogramyes"
                              name="amlprogram"
                              className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                              value="Yes"
                              checked={amlValue == true}
                              onChange={handleAMLRadioChange}
                            // checked=""
                            />
                            <span className="pl-3 text-gray-600 cursor-pointer select-none order-0 text-[#E4A795]">
                              Yes
                            </span>
                            <p className="pl-4 amlprogramtextyes mb-0 order-1 text-[#E4A795]">
                              We have or will be installing an AML Program
                            </p>
                          </label>

                          <label
                            className="flex flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2"
                            for="amlprogramno"
                          >
                            <Field
                              type="radio"
                              id="amlprogramno"
                              name="amlprogram"
                              className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                              value="No"
                              checked={amlValue == false}
                              onChange={handleAMLRadioChange}
                            />
                            <span className="pl-3 text-gray-600 cursor-pointer select-none order-0 text-[#E4A795]">
                              No
                            </span>
                            <p className="pl-4 amlprogramtextno mb-0 order-1 text-[#E4A795]">
                              We will not institute an AML program due to our
                              status as an ‘exempt’ retailer
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 mt-3  border-white border border-solid p-4">
                      <div className="flex flex-col min-w-0 break-words bg-clip-border-box rounded-sm">
                        <div className="flex-1 min-h-1 mt-3">
                          {/* <div className="flex flex-col sm:flex-row mb-6 gap-4 mb-4"> */}
                          <div className="flex flex-wrap">
                            <div className="bg-transparent border-0 text-18 text-black pb-0  mx-5 my-10 ">
                              <b className="text-[#E4A795]">
                                Digital signature
                              </b>
                            </div>
                            {/* <div className="mb-4 col-md-12 relative"> */}
                            <div className="px-4 mb-4 relative flex-none w-full max-w-full">
                              <label
                                className="text-[#E4A795] text-sm mb-1 inline-block"
                                for="comments"
                              >
                                Comments
                              </label>
                              <textarea
                                name="comments"
                                maxlength="1000"
                                // style="height: auto;"
                                // className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                className="bg-[#010519] text-white shadow-md block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5  bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                                id="comments"
                                rows="3"
                                values={values.comments}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    comments: value.target.value

                                  }))
                                }}
                              ></textarea>
                              <label className="float-right text-[#E4A795]">
                                <span id="commentCount">{values.comments != '' && values.comments != null ? 1000 - values.comments?.length : 1000}</span> /1000
                                characters remaining{" "}
                              </label>
                            </div>
                          </div>
                          <div className="accept-row">
                            <label
                              // className="flex-none items-center flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              htmlFor="acknowledgement"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="acknowledgement"
                                name="acknowledgement"
                                // value="true"
                                required
                              />
                              <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                The information provided above is accurate to
                                the best of my knowledge.{" "}
                                <span className="text-white ml-1">*</span>
                              </span>
                            </label>
                            <label
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              htmlFor="guarantees"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="guarantees"
                                name="guarantees"
                                value={values.gurantee}
                                checked={values.gurantee}
                                onChange={(same) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    gurantee: same.target.checked
                                  }))
                                }}

                              />
                              <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                GUARANTEE: In order to induce Solitaire Planet
                                INC. To sell merchandise
                                and extend credit to your company the
                                undersigned personally guarantees the prompt
                                payment of any, and all indebtedness which may
                                be incurred by the applicant to Solitaire Planet INC, and in
                                the event of any default at any time by the
                                applicant, Solitaire Planet INC shall be entitled to look to
                                the undersigned guarantor(s) immediately for
                                such payment without prior demand or notice.
                              </span>
                            </label>
                            <label
                              // className="flex-none items-center flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-3"
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              for="acknowledgement2"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="acknowledgement2"
                                name="acknowledgement2"
                                required
                              />
                              <span className="pl-3 text-[#E4A795] cursor-pointer select-none">
                                In the event this account must be referred to
                                an attorney or any agency For collection any
                                indebtedness owed by the guarantor or the
                                applicant to Solitaire Planet INC the guarantor agrees to pay
                                all reasonable costs thereof including
                                collection Fees, reasonable attorney Fees,
                                court costs and expenses incurred in
                                connection therewith.{" "}
                                <span className="text-white ml-1">*</span>
                              </span>
                            </label>
                          </div>
                          <div className="flex flex-col sm:flex-row mb-6 gap-4 pt-2 gap-12">
                            <div className="flex flex-col py-2.5  h-11 md:w-2/3 w-full text-[#E4A795] col-md-4 mb-4 max-w-full">
                              <label for="applicantName">
                                Applicant Name{" "}
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                maxlength="50"
                                name="applicantName"
                                className="bg-[#010519] text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="applicantName"
                                placeholder="Enter full name"

                                values={values.applicantName}

                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    applicantName: value.target.value
                                  }))
                                }}
                              />
                              {touched.applicantName && (
                                <p className="text-red-700 error_msg">
                                  {errors.applicantName}
                                </p>
                              )}
                            </div>
                            <div className="mb-4 col-md-1"></div>
                            <div className="flex flex-col py-2.5   h-11 md:w-1/3 w-full text-[#E4A795] mb-4 max-w-full">
                              <label for="applicantdate">
                                Date{" "}
                                <span className="text-white ml-1">*</span>
                              </label>
                              <Field
                                name="applicantdate"
                                className="bg-[#010519] text-white block w-full h-[calc(1.5em + 0.75rem + 2px)] py-5 px-2 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out datepicker-Field disabled:bg-stone-500"
                                id="applicantdate"
                                type="date"
                                value={todayDate}
                                readonly
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    date: todayDate
                                  }))
                                }}
                                disabled={true}
                                placeholder="Select date"
                              />
                              {/* {touched.applicantdate &&
                              errors.applicantdate && (
                                <p className="text-red-700 error_msg">
                                  {errors.applicantdate}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-[#E4A795] sm:flex-row mb-6 gap-4 mt-3 mb-4">
                    <div className="col-12">
                      <small>
                        By submitting this application, you agree to receive
                        marketing communication from Royal Chain Group. You
                        may opt out of receiving such communications at any
                        time.
                      </small>
                    </div>
                  </div>
                  <div classNameName="flex flex-wrap -mx-1">
                    <div className="flex  justify-center w-full max-w-full">
                      <Field
                        type="hidden"
                        id="pdfDataField"
                        name="pdfDataField"
                      />
                      <button
                        className="rounded-lg bg-[#E4A795] border border-text-[#E4A795] text-white cursor-pointer px-8 h-12 leading-12 uppercase tracking-wider btn btn-primary btn-block"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
};

export default Kyc;
