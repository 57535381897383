import React, { useState, useEffect } from "react";
import home_Two from "../assets/icons/home_Two.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";
import "../index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import carsouselImg from "../assets/icons/Ellipse.svg";
import { MdOutlineGroups } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { GiBigDiamondRing } from "react-icons/gi";
import { FaArrowRight } from "react-icons/fa";
import { useAuth } from "../auth/authContext";

const ToggleableCard = ({ title, expandedContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { setAuth } = useAuth();

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  
  const animationDirection = isOpen ? "fade-right" : "fade-right";
  useEffect(() => {
    AOS.init({ duration: 1500 });
    if(JSON.parse(JSON.stringify(localStorage.getItem('solitaireKey')))) setAuth(true)
  }, []);

  return (
    <div
      className={`my-8 aos-${animationDirection}`}
      data-aos={animationDirection}
    >
      <div
        className={`py-4 px-2 rounded-lg shadow-md flex items-center my-4 sm:mx-20 mx-2 gap-8 md:px-2 ${
          isOpen
            ? "mb-4 border-[#E4A795] bg-[#E4A795]"
            : "mb-0 border-[#E4A795] border-2"
        }`}
        onClick={handleToggle}
        role="button"
      >
        <div className="text-green-500">
          {isOpen ? (
            <MinusCircleIcon className="h-8 w-8 text-black" />
          ) : (
            <PlusCircleIcon className="h-8 w-8 text-[#E4A795]" />
          )}
        </div>
        <div className="ml-4">
          {isOpen ? (
            <p className="text-black text-lg font-semibold">{title}</p>
          ) : (
            <p className="text-[#E4A795] text-lg font-semibold">{title}</p>
          )}
          {isOpen && <p className="text-gray-700 mt-2">{expandedContent}</p>}
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cardData = [
    {
      id: 1,
      title:
        "Lorem ipsum dolor sit amet consectetur. In auctor sit nibh pellentesque pellentesque ut sit ?",
      expandedContent:
        "Lorem ipsum dolor sit amet consectetur. Purus etiam phasellus et luctus cursus praesent nibh ultricies luctus. Vitae malesuada proin nulla sed sit neque vestibulum. Nunc tempus massa quis tortor id ridiculus vel facilisis. Amet nulla quis felis dui. Erat cursus sit aenean dolor. Aliquet hendrerit ipsum in rhoncus ultricies. Mi donec lobortis phasellus velit. Ipsum varius tellus urna elit malesuada maecenas ultricies etiam.",
    },
    {
      id: 2,
      title:
        "Lorem ipsum dolor sit amet consectetur. In auctor sit nibh pellentesque pellentesque ut sit ?",
      expandedContent: "Additional content for Card 2.",
    },
    {
      id: 3,
      title:
        "Lorem ipsum dolor sit amet consectetur. In auctor sit nibh pellentesque pellentesque ut sit ?",
      expandedContent: "Additional content for Card 3.",
    },
    {
      id: 4,
      title:
        "Lorem ipsum dolor sit amet consectetur. In auctor sit nibh pellentesque pellentesque ut sit ?",
      expandedContent: "Additional content for Card 4.",
    },
  ];
  return (
    <div
      className="pb-20 gap-x-20"
      style={{
        backgroundColor: "#010519",
        // backgroundImage : `url(./assets/icons/bgbgbg.png)})`
      }}
    >
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');
        `}
      </style>
      {/* video with text */}
      <div className="flex justify-center border-[#E4A795] border-t-2 border-b-2 bg-video bg-cover w-full h-screen">
        <div className=" bg-[#010519] bg-opacity-75"></div>
        <div className="w-full p-4 md:p- md:order-1 order-1 max-h-fit h-full flex flex-col  text-center justify-center mx-auto my-auto bg-zinc-200 bg-opacity-10">
          <p
            className="font-bold mb-4 sm:text-4xl text-xl"
            style={{
              color: "var(--sec, #E4A795)",
              fontFamily: "Berkshire Swash, cursive",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            Timeless Elegance, Modern Spark
          </p>
          <p
            className="text-white text-5xl sm:text-8xl font-normal"
            style={{ fontFamily: "Belleza" }}
          >
            Welcome to Solitaire Planet
          </p>
          <p
            className="my-4 font-normal sm:text-4xl text-xl"
            style={{ fontFamily: "Belleza", color: "#f8ddc9" }}
          >
            New York-based Natural Diamond Manufacturer & Dealers
          </p>
          {/* <div className="flex justify-center my-4">
            <a href="/diamonds" className="px-8 py-4 bg-[#E4A795] rounded-md">
              Our Diamonds
            </a>
          </div> */}
          <div className="flex justify-center my-4">
            <a
              href="/diamonds"
              className="px-8 py-4 bg-[#E4A795] relative group flex items-center rounded-md text-xl md:font-base font-medium pr-8"
            >
              <span className="group-hover:translate-x-1  transition-transform md:ml-5">
                Our Diamonds
              </span>
              <FaArrowRight
                size={24}
                className="hidden md:flex arrow-icon opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer duration-500 ml-2"
              />
            </a>
          </div>
        </div>
      </div>
      {/* rigt image annd left text */}
      <div
        className="bg-right"
        //  style={{backgroundImage: `url(${background_1})` ,color: "red" ,backgroundSize : 'cover' , backgroundposition: 'right',
        // backgroundBlendMode: "multiply",}}
      >
        {/* style={{backgroundImage : `url(${background_1})`}} */}
        {/* bg-hero-pattern */}
        <div className=" bg-cover bg-right">
          {/* bg-hero-pattern */}

          {/* <div className="flex flex-col md:flex-row">
            <div className="w-full mt-4 md:w-1/3 md:order-2">
              <img src={diamonds} className="w-full" alt="diamonds" />
            </div>
            <div className="w-full md:w-2/3 p-4 md:p-8 md:order-1 order-1 max-h-fit h-full flex flex-col justify-between mx-auto my-auto">
              <p
                className="text-4xl font-bold mb-4 text-2xl"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Berkshire Swash, cursive",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Timeless Elegance, Modern Spark
              </p>
              <p
                className="text-white text-8xl font-normal"
                style={{ fontFamily: "Belleza" }}
              >
                Welcome to Solitaire Planet
              </p>
              <p
                className="max-w-xl my-4 font-normal text-3xl"
                style={{ fontFamily: "Belleza", color: "#f8ddc9" }}
              >
                New York-based Natural Diamond Manufacturer & Dealers
              </p>
              <div className="flex justify-center sm:justify-end my-4">
                <a href="/" className="px-4 py-2 bg-[#E4A795]">
                  Our Diamonds
                </a>
              </div>
            </div>
          </div> */}
          <div
        className="bg-right"
        >
        <div className=" bg-cover bg-right">
          <div
            className="xl:relative lg:mr-12 md:pd-2 flex flex-col md:flex-row py-8 sm:text-start text-center"
            data-aos="fade-up"
          >
            <div className="md:w-1/2 w-full md:p-4" data-aos="fade-right">
              <img src={home_Two} alt="Your Image" className="w-full" />
            </div>
            <div
              className="px-2 py-16 md:w-1/2 flex xl:absolute justify-center flex-col top-10 xl:left-[40%] 3xl:left-[45%] 2xl:left-[43%] md:px-8 left-2 text-white"
              data-aos="fade-up"
            >
              <p
                className="text-3xl md:text-4xl 2xl:text-5xl 2xl:text-6xl 2xl:text-7xl font-bold mb-4"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Brilliance Beyond Borders
              </p>
              <p
                className="md:text-7xl 2xl:text-6xl 3xl:text-7xl 4xl:text-8xl text-4xl sm:text-4xl font-normal"
                style={{ fontFamily: "Belleza" }}
              >
                Unmatched Quality, Unbeatable Prices!
              </p>
              <p
                className="font-normal 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl text-xl md:text-2xl pt-4 sm:text-start text-center"
                style={{
                  fontFamily: "Belleza",
                  color: "#f8ddc9",
                  lineHeight: "40px",
                }}
              >
                "Indulge in the luxury of quality diamonds without breaking the
                bank. Solitaire Planet brings you an exquisite collection, where
                transparency meets craftsmanship. Our commitment to honest
                pricing ensures you experience the brilliance of diamonds
                without compromise. Explore a world of elegance and
                affordability, where every gem is a testament to our dedication
                to excellence."
              </p>
            </div>
          </div>
</div>
</div>
          {/* image and text */}
          {/* <div
            className="xl:relative lg:mr-12 md:pd-2 flex flex-col md:flex-row py-8 text-center"
            data-aos="fade-up"
          >
            <div className="md:w-1/2 w-full md:p-4" data-aos="fade-right">
              <img src={home_Two} alt="Your Image" className="w-full" />
            </div>
            <div
              className="md:w-1/2 md:text-left text-center flex xl:absolute justify-center flex-col top-10 md:top-40 md:mt-20 xl:left-[45rem] md:px-8 left-2 text-white"
              data-aos="fade-up"
            >
              <p
                className="text-3xl md:text-4xl font-bold mb-4"
                style={{
                  color: "var(--sec, #E4A795)",
                  fontFamily: "Belleza",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Brilliance Beyond Borders
              </p>
              <p
                className="md:text-7xl text-4xl sm:text-4xl font-normal"
                style={{ fontFamily: "Belleza" }}
              >
                Unmatched Quality, Unbeatable Prices!
              </p>
              <p
                className="font-normal text-xl md:text-2xl pt-4 md:text-left text-center"
                style={{
                  fontFamily: "Belleza",
                  color: "#f8ddc9",
                  lineHeight: "40px",
                }}
              >
                "Indulge in the luxury of quality diamonds without breaking the
                bank. Solitaire Planet brings you an exquisite collection, where
                transparency meets craftsmanship. Our commitment to honest
                pricing ensures you experience the brilliance of diamonds
                without compromise. Explore a world of elegance and
                affordability, where every gem is a testament to our dedication
                to excellence."
              </p>
            </div>
          </div> */}
        </div>
        {/* journey with know more */}
        <div className="flex flex-col gap-x-14 lg:mt-20 ">
          <div data-aos="fade-up">
            <p
              className="text-center font-Belleza sm:text-4xl text-3xl font-normal"
              style={{ fontFamily: "Belleza", color: "#E4A795" }}
            >
              Your Journey, Our Commitment{" "}
            </p>
            <p
              className="text-white text-center font-Belleza text-5xl md:text-6xl xl:text-8xl text-2xl  mt-2 font-normal"
              style={{ fontFamily: "Belleza" }}
            >
              Why Choose Us?
            </p>

            <div
              className="flex lg:flex-row flex-col justify-between text-white gap-x-auto gap-y-6 my-12"
              data-aos="fade-up"
            >
              <div className="flex flex-col items-center ">
                {/* <FontAwesomeIcon className="w-16 h-16 text-red-400" icon="fa-regular fa-ring-diamond" /> */}
                {/* <FontAwesomeIcon icon={faRingDiamond} /> */}

                {/* <i class="fa-solid fa-ring-diamond" className="h-60 text-red-700"></i> */}
                <GiBigDiamondRing size={110} className="text-[#E4A795]" />
                {/* <img
                src={Journey_1}
                alt="Image 1"
                className="h-auto text-red-700"
                // lg:w-1/5 md:w-2/5 w-3/5
              /> */}
                <div className="mt-2 flex flex-col items-center justify-center text-center gap-y-2">
                  <p className="text-white text-2xl">Natural Diamond</p>
                  <p className="text-base text-[#E4A795] p-2">
                    “Our passion lies in the meticulous art of diamond
                    craftsmanship, where each piece tells a story, capturing
                    moments that last a lifetime.”
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <MdOutlineGroups size={110} className="text-[#E4A795]" />
                {/* <img
                src={Journey_2}
                alt="Image 1"
                className="h-auto"
                // lg:w-1/5 md:w-2/5 w-3/5
              /> */}
                <div className="mt-2 flex flex-col items-center justify-center text-center gap-y-2">
                  <p className="text-white text-2xl">10+ Years Experience</p>
                  <p className="text-base text-[#E4A795] p-2">
                    "For a decade, Solitaire Planet's roots in India's diamond
                    traditions define us, honing skills to craft diamonds that
                    radiate exceptional brilliance and cultural richness."
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <VscWorkspaceTrusted size={100} className="text-[#E4A795]" />
                {/* <img
                src={Journey_3}
                alt="Image 1"
                className="h-auto"
                // lg:w-1/5 md:w-2/5 w-3/5
              /> */}
                <div className="mt-2 flex flex-col items-center justify-center text-center gap-y-2">
                  <p className="text-white text-2xl mt-2">Trusted Clients</p>
                  <p className="text-base text-[#E4A795] p-2">
                    “Solitaire Planet is trusted by a discerning clientele
                    worldwide, standing as a symbol of reliability,
                    transparency, and uncompromising quality.”
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <HiOutlineEmojiHappy size={110} className="text-[#E4A795]" />
                {/* <img
                src={Journey_4}
                alt="Image 1"
                className="h-auto"
                // lg:w-1/5 md:w-2/5 w-3/5
              /> */}
                <div className="mt-2 flex flex-col items-center justify-center text-center gap-y-2">
                  <p className="text-white text-2xl">Happy Customers</p>
                  <p className="text-base text-[#E4A795] p-2">
                    "Joyful testimonials echo the experience—where trust meets
                    elegance, and every diamond embodies a story of enduring
                    beauty and satisfied hearts."
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center my-4">
            <a
              href="/diamonds"
              className="px-8 py-4 bg-[#E4A795] relative group flex items-center rounded-md text-xl md:font-base font-medium pr-8"
            >
              <span className="group-hover:translate-x-1  transition-transform md:ml-5">
              Know More
              </span>
              <FaArrowRight
                size={24}
                className="hidden md:flex arrow-icon opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer duration-500 ml-2"
              />
            </a>
          </div>
          <div className="lg:mt-20 md:mt-12 mt-12 " data-aos="fade-up">
            <p
              className="text-[#E4A795] text-center  sm:text-4xl text-2xl font-normal leading-normal"
              style={{
                fontFamily: "Belleza",
              }}
            >
              Client Reviews{" "}
            </p>
            <p
              className="text-white text-center  text-4xl sm:text-6xl font-normal leading-normal"
              style={{
                fontFamily: "Belleza",
              }}
            >
              Stories from Satisfied Souls
            </p>
          </div>

          {/* <Carousel /> */}

          <div
            className="mt-16 w-4/5 sm:w-9/12 md:w-11/12 m-auto gap-y-12 text-white"
            data-aos="fade-up"
          >
            <Slider {...settings}>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl flex justify-center">
                <div className="flex flex-col xl:flex-row py-auto text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={carsouselImg} alt="" className="rounded-full " />
                  </div>
                  <div className="gap-4">
                    <p className="text-left  mb-4">
                      Lorem ipsum dolor sit amet consectetur. Aliquam morbi
                      laoreet adipiscing porttitor nec pharetra nunc tortor
                      justo. Posuere pellentesque imperdiet posuere vehicula at
                      et.{" "}
                    </p>
                    <p className="text-xl font-semibold">Mark Johnson</p>
                    <p className="text-base font-normal">New York, USA</p>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row  text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={carsouselImg} alt="" className="rounded" />
                  </div>
                  <div className="gap-4">
                    <p className="text-left  mb-4">
                      Lorem ipsum dolor sit amet consectetur. Aliquam morbi
                      laoreet adipiscing porttitor nec pharetra nunc tortor
                      justo. Posuere pellentesque imperdiet posuere vehicula at
                      et.{" "}
                    </p>
                    <p className="text-xl font-semibold">prs Mark Johnson</p>
                    <p className="text-base font-normal">New York, USA</p>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={carsouselImg} alt="" className="rounded" />
                  </div>
                  <div className="gap-4">
                    <p className="text-left  mb-4">
                      Lorem ipsum dolor sit amet consectetur. Aliquam morbi
                      laoreet adipiscing porttitor nec pharetra nunc tortor
                      justo. Posuere pellentesque imperdiet posuere vehicula at
                      et.{" "}
                    </p>
                    <p className="text-xl font-semibold">Mark Johnson</p>
                    <p className="text-base font-normal">New York, USA</p>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={carsouselImg} alt="" className="rounded" />
                  </div>
                  <div className="gap-4">
                    <p className="text-left  mb-4">
                      Lorem ipsum dolor sit amet consectetur. Aliquam morbi
                      laoreet adipiscing porttitor nec pharetra nunc tortor
                      justo. Posuere pellentesque imperdiet posuere vehicula at
                      et.{" "}
                    </p>
                    <p className="text-xl font-semibold">Mark Johnson</p>
                    <p className="text-base font-normal">New York, USA</p>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={carsouselImg} alt="" className="rounded" />
                  </div>
                  <div className="gap-4">
                    <p className="text-left  mb-4">
                      Lorem ipsum dolor sit amet consectetur. Aliquam morbi
                      laoreet adipiscing porttitor nec pharetra nunc tortor
                      justo. Posuere pellentesque imperdiet posuere vehicula at
                      et.{" "}
                    </p>
                    <p className="text-xl font-semibold">Mark Johnson</p>
                    <p className="text-base font-normal">New York, USA</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          {/* FAQ with toggle text */}
          <div className="lg:mt-20 md:mt-12 mt-12 " data-aos="fade-up">
            <p
              className="text-[#E4A795] text-center sm:text-4xl text-2xl font-normal leading-normal"
              style={{
                fontFamily: "BBelleza",
              }}
            >
              FAQs{" "}
            </p>
            <p
              className="text-white text-center  text-4xl sm:text-7xl font-normal leading-normal"
              style={{
                fontFamily: "BBelleza",
              }}
            >
              Your Queries, Our Answers
            </p>
          </div>
          <div className="mt-5">
            <div className="container mx-auto">
              {cardData.map((card) => (
                <ToggleableCard
                  key={card.id}
                  title={card.title}
                  expandedContent={card.expandedContent}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
