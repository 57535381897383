import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/navigation";
import NotNavigationBar from "./components/notNavigation";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Diamonds from "./pages/Diamonds";
import Footer from "./components/footer";
import LoginForm from "./pages/Signinform";
// import Individual from './pages/individualLogin';
import Individual from "./pages/individualLogin";
import RegistrationPage from "./pages/register";
import RegistrationForm from "./pages/registerpage";
import { Logout } from "./pages/logout";
import { useAuth } from "./auth/authContext";
import Kyc from "./pages/Kyc";
import Special from "./pages/special";


function App() {

  const { auth } = useAuth();

  return (
    <Router>
      {auth ? <NavigationBar /> : <NotNavigationBar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/diamonds" element={<Diamonds />} />
        <Route path="/special" element={<Special />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/kyc" element={<Kyc/>} />
        <Route path="/signin" element={<LoginForm></LoginForm>} />
        <Route path="/businesslogin" element={<Individual />} />
          <Route path="/register" element={<RegistrationPage />}>
            <Route path=":option" element={<RegistrationForm />} />
          </Route>
        <Route path="/logout" element={<Logout />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
