// Signinform.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import { loginFormSchema } from "../schema/index";
import { MdBusinessCenter } from "react-icons/md";
import { IoPersonCircle } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa6";
import app_api from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../auth/authContext";
import MyModal from "../components/modal";

const Signinform = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isContinueActive, setContinueActive] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setContinueActive(true);
  };

  const { setAuth } = useAuth();
  useEffect(() => {
    if (JSON.parse(JSON.stringify(localStorage.getItem("solitaireKey"))))
      setAuth(true);
  });

  const toggleLogin = () => {
    console.log("Toggling loginOpen");
    setLoginOpen(!loginOpen);
  };

  // const toggleforgot = () => {
  //   console.log("Toggling loginOpen");
  //   setForgotOpen(!forgotOpen);
  // };
  const toggleforgot = () => {
    console.log("Toggling forgotOpen");
    setForgotOpen(!forgotOpen);
  };

  const handleResetPassword = () => {
    app_api
      .post("/forgot-password", { email: email })
      .then((res) => {
        if (res.data.success) {
          setSuccessMessage(res.data.message);
          setErrorMessage("");
        } else {
          setSuccessMessage("Success full check you mail");
          //   setErrorMessage(res.data.error || 'Error resetting password. Please try again.');
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        setSuccessMessage("");
        setErrorMessage("Error resetting password. Please try again.");
      });
  };

  return (
    <div className="lg:flex items-center md:justify-center lg:h-screen md:h-full bg-[#010519] bg-blend-color-dodge bg-right bg-cover md:-mt-20">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          app_api
            .post("auth/login", values)
            .then((res) => {
              toast.success("Login successful");
              localStorage.setItem(
                "solitaireKey",
                JSON.stringify(res.data.data)
              );
              setAuth(true);
              setSubmitting(false);
              setTimeout(function () {
                if(res.data.data.role == 'BUSINESS' && res.data.data.kyc_status == false){
                  navigate('/kyc')
                }
                else{
                  navigate("/");
                }
              }, 2000);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setSubmitting(false);
            });
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          isValid,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="bg-[#010519] md:border md:border-[#E4A795] p-8 shadow-md rounded-lg lg:w-2/5 md:w-full"
          >
            <h2
              className="text-2xl font-bold mb-4 text-center"
              style={{ fontFamily: "Belleza" }}
            >
              <p className="text-white md:text-5xl text-2xl font-sans">Login</p>
              <p className="text-[#E4A795] md:text-xl text-xl">
                to your account
              </p>
            </h2>
            <div className="mb-4">
              <label
                className="block text-lg font-bold mb-2 text-[#E4A795]"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`border rounded w-full py-2 px-3 bg-[#010519] text-white ${
                  touched.email && errors.email ? "border-red-500" : ""
                }`}
                required
              />
              {touched.email && errors.email && (
                <div className="text-red-500 text-sm mt-1">{errors.email}</div>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-lg font-bold mb-2 text-[#E4A795]"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`border rounded w-full py-2 px-3 bg-[#010519] text-white 	${
                  touched.password && errors.password ? "border-red-500" : ""
                }`}
                required
              />
              {touched.password && errors.password && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="text-right text-[#E4A795] text-sm my-4 underline">
              {" "}
              {/* <a href="">
                Forgot Your Password&nbsp;?
                </a> */}
              {/* <a
                  className=" text-[#e4a799] cursor-pointer"
                  onClick={forgotuser}
                > */}
              {/* <a
                className=" text-[#e4a799] cursor-pointer"
                onClick={toggleforgot}
                // onClick={openModal}
              >
                Forgot Your Password&nbsp;?
              </a> */}
              <a
                className="text-[#e4a799] cursor-pointer"
                onClick={toggleforgot}
              >
                Forgot Your Password&nbsp;?
              </a>
            </div>
            <div className="w-full">
              <button
                className="w-full cursor-pointer mt-2 flex justify-center text-black bg-[#E4A795] font-semibold md:text-xl py-2 px-4 rounded"
                type="submit"
              >
                Login
              </button>
            </div>
            <div className="flex flex-row mt-4">
              <p className="text-white">
                Don’t have an account?&nbsp;
                <a
                  className=" text-[#e4a799] cursor-pointer"
                  onClick={toggleLogin}
                >
                  Create a new one
                </a>
              </p>
            </div>
          </form>
        )}
      </Formik>

      {loginOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 cursor-pointer">
          <div className=" bg-[#010519] absolute w-full h-full"></div>
          <div className="bg-clip-content border-2 border-[#E4A795] border-solid bg-[#010519] text-white p-12 rounded-md shadow-md relative z-10 gap-6 md:w-3/6 md:1/2">
            <h2
              className="text-4xl mb-4 font-normal text-2xl text-center leading-[50px]"
              style={{ fontFamily: "Belleza" }}
            >
              I am a...
            </h2>
            <div className="flex md:flex-row md:gap-y-6 flex-col gap-5 justify-around text-center">
              <div
                className={`flex w-60 items-center justify-center flex-col bg-clip-content border-2 border-[#E4A795] border-solid rounded-md ${
                  selectedOption === "individual"
                    ? "bg-[#F8DDC9] text-black"
                    : "text-[#F8DDC9] "
                }`}
                onClick={() => handleOptionClick("individual")}
                // Adjust the width accordingly
              >
                <span className="py-4">
                  <IoPersonCircle size={160} />
                  <p
                    className="font-light text-xl leading-10"
                    style={{ fontFamily: "Belleza" }}
                  >
                    Individual Customer
                  </p>
                </span>
              </div>

              <div
                className={`flex w-60 items-center justify-center flex-col bg-clip-content border-2 border-[#E4A795] border-solid rounded-md ${
                  selectedOption === "business"
                    ? "bg-[#F8DDC9] text-black"
                    : "text-[#F8DDC9] "
                }`}
                onClick={() => handleOptionClick("business")}
              >
                <span className="py-4">
                  <FaBriefcase size={160} />
                  <p
                    className="font-light text-xl leading-10"
                    style={{ fontFamily: "Belleza" }}
                  >
                    Business Owner
                  </p>
                </span>
              </div>
            </div>

            <Link
              to={isContinueActive ? `/register/${selectedOption}` : "#"}
              className={`flex items-center justify-center text-center mt-10 bg-[#E4A795] px-4 py-2 rounded w-5/6 mx-auto ${
                isContinueActive ? "" : " hidden"
              }`}
              onClick={isContinueActive ? null : (e) => e.preventDefault()}
              style={{ fontFamily: "Belleza" }}
            >
              <span>Proceed to Sign In</span>
            </Link>
          </div>
        </div>
      )}

      {forgotOpen && (
        <MyModal
          isOpen={forgotOpen}
          onClose={closeModal}
          onRequestClose={toggleforgot}
          contentLabel="Forgot Password Modal"
        >
          {/* Modal Content */}
          <div className="text-black">
            <div className="flex flex-col z-50 w-full bg-[#010519]">
              <div className="top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer">
                <div className="bg-[#010519] absolute w-full h-full"></div>
                <div className="w-full relative max-w-xl p-8 text-white border-solid border-2 border-[#E4A795] rounded-md shadow-md">
                  <h1 className="text-2xl text-white font-semibold mb-6 text-center">
                    Forgot Password?
                  </h1>
                  
                  <div className="flex flex-col">
                  <div className="mb-4">
                    <label className="block text-sm font-semibold mb-2 text-[#E4A795]">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="border rounded w-full py-2 px-3 text-black"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                    <button
                      className="bg-[#E4A795] text-white px-4 py-2 rounded hover:bg-pink-300"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                    {successMessage && (
                      <p className="text-green-600 mt-4">{successMessage}</p>
                    )}
                    {errorMessage && (
                      <p className="text-red-600 mt-4">{errorMessage}</p>
                    )}
                    <div  className="flex flex-row items-center gap-2  text-white hover:text-[#E4A795] mt-2"  onClick={toggleforgot}>
                      
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mt-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
</svg>

                    
                      <a className="mt-2">
                      Back to login
                    </a>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MyModal>
      )}
      <ToastContainer />
    </div>
  );
};

export default Signinform;
