// RegistrationPage.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import RegistrationForm from "./registerpage";

const RegistrationPage = () => {
  const { option } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
  }, [option]);

  return (
    <div className="bg-[#010519]">
      <RegistrationForm selectedOption={selectedOption} />
    </div>
  );
};

export default RegistrationPage;