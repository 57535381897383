import React from 'react';

const FormInput = ({ label, type, name, value, onChange, placeholder, options }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-xl font-normal"  style={{ color: "#E4A795" }}>
        {label}
      </label>

      {type === 'select' ? (
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="mt-1 p-2 w-full border rounded-md"
          style={{backgroundColor: "#010519" }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="mt-1 p-2 w-full border rounded-md text-white"
          style={{backgroundColor: "#010519" }}
        />
      )}
    </div>
  );
};

export default FormInput;
