import React, { useState } from "react";
import FormInput from "../components/formInputs";

const YourFormComponent = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    companyName: "",
    businessType: "",
    address: "",
    city: "",
    zipCode: "",
    countryCode: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your form submission logic here
    console.log("Form submitted:", formData);
  };

  const businessTypeOptions = [
    { value: "retailer", label: "Retailer" },
    { value: "individual", label: "Individual" },
    // { value: "option3", label: "Option 3" },
  ];

  const countryTypeOptions = [
    { value: "americanSamoa", label: "American Samoa" },
    { value: "congo", label: "Congo" },
    { value: "canada", label: "Canada" },
  ];

  return (
    <div className="text-white pb-20" style={{ backgroundColor: "#010519" }}>
      <div className="container mx-auto my-auto">
        <div className="flex gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-16 h-16 font-normal"
              style={{ color: "#E4A795" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          <h1 className="font-normal text-6xl">Register</h1>
        </div>
        <div className="flex md:justify-between flex-col sm:py-16 py-16">
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
              <FormInput
                label="First Name"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="Enter your first name"
              />

              <FormInput
                label="Last Name"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Enter your last name"
              />

              <FormInput
                label="E-mail ID"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="random@gmail.com"
              />
              <FormInput
                label="Phone Number"
                type="number"
                name="number"
                value={formData.number}
                onChange={handleInputChange}
                placeholder="000-0000000000"
              />

              <FormInput
                label="Company Name"
                type="password"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Enter your company name"
              />

              <FormInput
                label="Business Type"
                type="select"
                name="businessType"
                value={formData.businessType}
                onChange={handleInputChange}
                options={businessTypeOptions}
              />

              <FormInput
                label="Address"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
              />

              <FormInput
                label="City"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter your city"
              />

              <FormInput
                label="Zip Code"
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
                placeholder="Enter your zip code"
              />

              <FormInput
                label="Country"
                type="select"
                name="countryType"
                value={formData.countryType}
                onChange={handleInputChange}
                options={countryTypeOptions}
              />

              <FormInput
                label="Enter New Password"
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                placeholder="Enter Password"
              />

              <FormInput
                label="Confirm New Password"
                type="password"
                name="confirmpassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm password"
              />
            </div>
            <div className="flex justify-center mt-5">
              <button
                className="py-4 px-6 rounded-lg font-semibold text-xl"
                type="submit"
                style={{ background: "#E4A795" }}
              >
                Register
              </button>
            </div>
            <div className="flex justify-center mt-5">
              <a className="font-semibold text-xl" style={{ color: "#E4A795" }}>
                Back to Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default YourFormComponent;




// import React from 'react';
// import { useFormik } from 'formik';
// import {loginIndividualSchema} from "../schema/index"

// const Individual = () => {
//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: '',
//     },
//     validationSchema: loginIndividualSchema,
//     onSubmit: (values) => {
//       console.log('Form data submitted:', values);
//     },
//   });

//   return (
//     <div className="flex items-center justify-center h-screen bg-[#010519] mt-[1px]">
//       <form onSubmit={formik.handleSubmit} className="bg-white p-8 shadow-md rounded-lg lg:w-2/5 md::w-2/5">
//         <h2 className="text-2xl font-bold mb-4">        Login        </h2>
//         <div className="mb-4">
//           <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
//             Email
//           </label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formik.values.email}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             className={`border rounded w-full py-2 px-3 ${
//               formik.touched.email && formik.errors.email ? 'border-red-500' : ''
//             }`}
//             required
//           />
//           {formik.touched.email && formik.errors.email && (
//             <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
//           )}
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
//             Password
//           </label>
//           <input
//             type="password"
//             id="password"
//             name="password"
//             value={formik.values.password}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             className={`border rounded w-full py-2 px-3 ${
//               formik.touched.password && formik.errors.password ? 'border-red-500' : ''
//             }`}
//             required
//           />
//           {formik.touched.password && formik.errors.password && (
//             <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
//           )}
//         </div>
//         <div className="text-right text-[#E4A795] text-sm">
//           {" "}
//           <a className=" cursor-pointer"><u>Forgotpassword?</u></a>
//         </div>
//         <button
//           type="submit"
//           className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
//         >
//           Login
//         </button>
//         <div className="flex flex-row mt-2">
//             <p>Don’t have an account?</p>&nbsp;
//             <a href="" className=" text-[#e4a799]"><u>Create a new one</u></a>
            
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Individual;
